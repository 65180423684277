import React, { ForwardRefRenderFunction, useCallback, useState } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";

const LISTBOX_PADDING = 8; // px

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLUListElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return (
        <ul
            ref={ref}
            {...props}
            {...outerProps}
        />
    );
});

const renderRow = (props: ListChildComponentProps) => {
    const { data, index, style } = props;
    const { children, setItemHeight } = data[index];

    const rowRef = (node: HTMLElement | null) => {
        if (node) {
            const height = node.getBoundingClientRect().height;
            setItemHeight(index, height);
        }
    };

    return React.cloneElement(children, {
        ref: rowRef,
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
            whiteSpace: "normal",
            wordWrap: "break-word",
            height: "auto",
        },
    });
};

function useResetCache(itemNumber: number) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [itemNumber]);

    return ref;
}

const VirtualizedListbox: ForwardRefRenderFunction<HTMLDivElement, React.HTMLAttributes<HTMLElement>> = (props, ref) => {
    const { children, ...other } = props;
    const [itemHeights, setItemHeights] = useState<Record<number, number>>({});

    const setItemHeight = useCallback((index: number, height: number) => {
        setItemHeights((prev) => {
            if (prev[index] === height) {
                // Skip update if unchanged
                return prev;
            }

            gridRef.current?.resetAfterIndex(index, true);
            return { ...prev, [index]: height };
        });
    }, []);

    const getItemSize = useCallback(
        (index: number): number => itemHeights[index] || 50, // Default fallback height
        [itemHeights]
    );

    const itemCount = React.Children.count(children);

    const itemData = React.Children.map(children, (child, index) => ({
        children: child,
        setItemHeight,
    }));
    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    ref={gridRef}
                    height={300}
                    width="100%"
                    itemSize={getItemSize}
                    itemCount={itemCount}
                    itemData={itemData}
                    innerElementType="ul"
                    outerElementType={OuterElementType}
                    overscanCount={5}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
};

export default React.forwardRef(VirtualizedListbox);
