import { generatePath } from "react-router-dom";
import { PathParameters } from "./PathParameters";

export const Paths = {
    AccountingPeriodList: "/accounting-periods",
    AccountingPeriodForm: "/accounting-periods/form/:year/:month",

    Administration: {
        DepartmentStructure: "/administration/structure",
        UsersList: "/administration/users",
        UserForm: "/administration/users/form/:id?",
        ProcedureList: "/administration/procedures",
        ProcedureForm: "/administration/procedures/form/:id?",
        RolesList: "/administration/roles",
        RoleForm: "/administration/roles/form/:role?",
    },

    AnalyticGroupsList: "/analytic-groups",
    AnalyticGroupForm: "/analytic-groups/form/:id",

    BalanceDebtsList: "/balance-debts",
    BalanceDebtForm: "/balance-debts/form/:id?",

    Bridge: {
        MarketingToolsList: "/bridge/marketing-tools",
        MarketingToolForm: "/bridge/marketing-tools/form/:id?",
        PromotionPeriodsList: "/bridge/promotion-periods/",
        PromotionPeriodForm: "/bridge/promotion-periods/form/:promotionPeriodId?",
        ToolkitsList: "/bridge/toolkits",
        ToolkitForm: "/bridge/toolkits/form/:toolkitId?",
        ReservationKitsList: "/bridge/reservation-kits",
        ReservationKitForm: "/bridge/reservation-kits/form/:reservationKitId?",
        PromotionCreatorDraftsList: "/bridge/promotion-creators/draft",
        PromotionCreatorDraftForm: "/bridge/promotion-creators/draft/form/:id?",
        PromotionCreatorSearch: "/bridge/promotion-creators/search",
    },

    CampaignsList: "/campaigns",
    CampaignForm: "/campaigns/form/:id?",

    Calendar: {
        DeliveryWindows: "/calendar/delivery-windows",
        DeliveryCostsList: "/calendar/delivery-costs",
        DeliveryCostForm: "/calendar/delivery-costs/form/:id?",
    },

    Cms: {
        CmsList: "/cms/cms",
        CmsForm: "/cms/cms/form/:id?",
        ContestsList: "/cms/contests",
        ContestForm: "/cms/contests/form/:id?",
        RulesList: "/cms/rules",
        RuleForm: "/cms/rules/form/:id?",
    },

    Content: {
        Alerts: "/content/alerts",
        BrandsList: "/content/brands",
        BrandForm: "/content/brands/form/:brandId?",
        CategoryTreesList: "/content/categoryTrees",
        CategoryTreeForm: "/content/categoryTrees/form/:id?",
        CategoriesList: "/content/categories",
        CategoryForm: "/content/categories/form/:id?",
        InitProductsList: "/content/initProducts",
        InitProduct: "/content/products/init",
        InitProductForm: "/content/initProducts/:id",
        ProductsList: "/content/products",
        ProductForm: "/content/products/:id",
        ProductAttributesList: "/content/product-attributes",
        ProductAttributeForm: "/content/product-attributes/form/:id?",
    },

    Crawler: {
        ExternalProductsList: "/crawler/external-product/:id?",
        ExternalProductForm: "/crawler/external-product/:shopId/form/:id",
        ExternalProductScanForm: "/crawler/external-product/:shopId/:productId/scans/:id",
        ScanStatus: "/crawler/product-scanning-status",
        ShopAuthorizationsList: "/crawler/shop-authorization",
        ShopAuthorizationForm: "/crawler/shop-authorization/form/:shopId?",
        ShopConfigurationsList: "/crawler/shop-configuration",
        ShopConfigurationForm: "/crawler/shop-configuration/form/:id",
        ProductSubstitutesList: "/crawler/product-substitute",
        ProductSubstituteForm: "/crawler/product-substitute/form/:id?",
    },

    Customer: {
        List: "/customers",
        Form: "/customers/:customerId",
        Edit: "/customers/:customerId/edit",
        Voucher: "/customers/:customerId/voucher/:complaintId?",
        DeferredPayment: "/customers/:customerId/deferred-payments/form/:deferredPaymentNumber?",
        OperationOrderForm: "/customers/:customerId/operations-orders/:orderNumber/:complaintNumber?",
        OrderForm: "/customers/:customerId/orders/:orderNumber/:complaintNumber?",
        Complaint: "/customers/:customerId/orders/:orderNumber/complaints/:documentId/:complaintId?",
        SupportContact: "/customers/:customerId/support-contacts",
    },

    CustomerDepositsImport: "/customerDeposits/import",

    Dashboard: "/dashboard",

    DeliveryComplaints: "/delivery-complaints",

    FixedAssets: {
        DevicesList: "/fixed-assets/devices",
        DevicesForm: "/fixed-assets/devices/form/:id?",
        DevicesAssignmentList: "/fixed-assets/device-assignments",
        DevicesAssignmentEmployeeList: "/fixed-assets/device-assignments/employee/:id?",
        DevicesAssignmentForm: "/fixed-assets/device-assignments/form/:id?",
        WorkstationList: "/workstations",
        WorkstationForm: "/workstations/form/:id?",
        DevicesAssignmentWorkstationList: "/fixed-assets/device-assignments/workstation/:id?",
        DeviceAssets: "/fixed-assets/asset-numbers",
    },

    Imports: {
        RetentionManagers: "/imports/retention-managers",
        UsersBalanceDebts: "/imports/users-balance-debts",
    },

    DocumentArchive: {
        List: "/document-archive/document",
        New: "/document-archive/document/add",
        Form: "/document-archive/document/:documentId",
        PermissionsList: "/document-archive/permissions",
        PermissionForm: "/document-archive/permissions/form/:id",
    },

    Dictionaries: {
        CnStakesList: "/dictionaries/cn-stakes",
        CnStakeForm: "/dictionaries/cn-stakes/form/:id?",
        ContactTypesList: "/dictionaries/contact-types",
        ContactTypeForm: "/dictionaries/contact-types/form/:id?",
        DocumentCategoriesList: "/dictionaries/document-categories",
        DocumentCategoryForm: "/dictionaries/document-categories/form/:id?",
        DocumentDefinitionsList: "/dictionaries/document-definitions",
        DocumentDefinitionForm: "/dictionaries/document-definitions/form/:id?",
        DocumentTypesList: "/dictionaries/document-types",
        DocumentTypeForm: "/dictionaries/document-types/form/:id?",
        ProcedureCategoriesList: "/dictionaries/procedure-categories",
        ProcedureCategoryForm: "/dictionaries/procedure-categories/form/:id?",
        DeviceTypeList: "/dictionaries/device-types",
        DeviceTypeForm: "/dictionaries/device-types/form/:id?",
    },

    DotpayPayouts: "/dotpay-payouts",

    DynamicSegments: "/dynamic-segments",
    DynamicSegmentForm: "/dynamic-segments/form/:dynamicSegmentId?",

    EmailsList: "/emails",
    EmailForm: "/emails/form/:id?",

    CompanyStructure: "/company-structure",

    ExternalUsers: {
        SubsidiaryGainsList: "/external-users/subsidiary-gains",
        SubsidiaryGainForm: "/external-users/subsidiary-gains/:subsidiaryGainId",
        ProductInitsList: "/external-users/product-inits",
        ProductInitForm: "/external-users/product-inits/:id",
    },

    Offers: {
        ContentsList: "/offers/content",
        ContentForm: "/offers/content/form/:id?",
        ContractsList: "/offers/contracts",
        ContractForm: "/offers/contracts/form/:id?",
        DiscountsList: "/offers/discounts",
        DiscountForm: "/offers/discounts/form/:id?",
        FeedsList: "/offers/feed",
        FeedForm: "/offers/feed/form/:id?",
        GiftsList: "/offers/gifts",
        GiftForm: "/offers/gifts/form/:id?",
        GiftChoicesList: "/offers/gifts/:giftId/gift-choices",
        GiftChoiceForm: "/offers/gifts/:giftId/gift-choices/form/:id?",
        ProductPreviewSearch: "/offers/product",
        ProductPreview: "/offers/product/:id",
        PromotionsList: "/offers/promotions",
        PromotionForm: "/offers/promotions/form/:id?",
        RewardsList: "/offers/rewards",
        RewardForm: "/offers/rewards/form/:id?",
        PurchaseSchedulesList: "/offers/purchase-schedules",
        PurchaseScheduleForm: "/offers/purchase-schedules/form/:id?",
        StockReservationsList: "/offers/stock-reservations",
        StockReservationForm: "/offers/stock-reservations/form/:id?",
    },

    OrderAlertsList: "/orderAlerts",
    OrderAlertForm: "/orderAlerts/:customerId/:orderNumber",
    OrderSearch: "/orderSearch",

    PaymentTransfers: "/paymentTransfers",

    Pricing: {
        WaterLimitsList: "/pricing/water-limits",
        WaterLimitForm: "/pricing/water-limits/form/:id?",
        ProductLimitsList: "/pricing/product-limits",
        ProductLimitForm: "/pricing/product-limits/form/:id?",
        ProductRationsList: "/pricing/product-rations",
        ProductRationForm: "/pricing/product-rations/form/:id?",
        ProductRestrictionsList: "/pricing/product-restrictions",
        ProductRestrictionForm: "/pricing/product-restrictions/form/:id?",
    },

    PriceLists: "/price-lists",

    Procedures: "/procedures",
    Procedure: "/procedures/form/:id?",

    ReplacementsList: "/replacements",
    ReplacementForm: "/replacements/form/:id?",

    Reports: {
        CorrectionRequest: "/reports/correction-request/:settlementType",
        CorrectionRequestRetro: "/reports/correction-request/retro",
        CorrectionRequestsReport: "/reports/correction-requests",
        Crawler: "/reports/crawler-linking",
        SubsidiaryGain: "/reports/subsidiary-gain",
        ProductsSeo: "/reports/products-seo",
        VendorSettlementReport: "/reports/vendor-settlements",
        VendorTurnoverReport: "/reports/vendor-turnovers",
    },

    Reviews: "/reviews",

    Search: {
        KeywordsList: "/search/keywords",
        KeywordForm: "/search/keywords/form/:id?",
        Synonyms: "/search/synonyms/:language",
        PhraseBoostsList: "/search/phrase-boost",
        PhraseBoostForm: "/search/phrase-boost/form/:id?",
    },

    VatCorrections: "/vat-corrections",

    Vendor: {
        List: "/vendors",
        Form: "/vendors/:id",
        CertificateForm: "/vendors/:vendorId/certificates/form/:certificateId?",
        ContractForm: "/vendors/:vendorId/contracts/form/:contractId?/:typeOfChange?",
        CorrectionRequestForm: "/vendors/:vendorId/correction-request/:correctionRequestId?",
        CreditLimitForm: "/vendors/:vendorId/credit-limits/form/:creditLimitId?",
        MarketingBudgetForm: "/vendors/:vendorId/marketing-budgets/form/:marketingBudgetId?",
        Document: "/vendors/:vendorId/documents/form/:id?",
        OrderForm: "/vendors/:vendorId/orders/:orderId",
        RefundationForm: "/vendors/:vendorId/refundations/form/:refundationId?",
        SubsidiaryGainForm: "/vendors/:vendorId/subsidiary-gains/form/:subsidiaryGainId?",
        Turnover: "/vendors/:vendorId/turnovers/form/:id",
    },

    VendorDecisions: "/vendor-decisions",

    VendorOperationsList: "/vendor-operations",
    VendorOperationForm: "/vendors/operation/:operationId/:vendorId?",

    VouchersList: "/vouchers/:type",
    VoucherForm: "/vouchers/:type/form/:id?",

    GeneratePath: (path: string, parameters?: PathParameters<{}>): string => {
        return generatePath(path, { ...parameters });
    },
};
