import { isInteger } from "utils/numberExtensions";
import Validator, { greaterOrEqualsThan, greaterThan, mustBeNumber, notEmptyValidator } from "utils/validators/basic";
import { get, isEmpty } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

const productId = (value) => notEmptyValidator(value).validate();
const mappedProductId = (_, form, __, path) => {
    const effect = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    return notEmptyValidator(effect)
        .must((e) => {
            return isEmpty(e) || isEmpty(e.mappedProductId) || (!isEmpty(e.mappedProductId) && e.mappedProductId !== e.productId);
        }, tValidation(validation.mappedProductIdMustBeDiffrent))
        .validate();
};

const price = (value) =>
    notEmptyValidator(value)
        .must(greaterOrEqualsThan(0.01), tValidation(validation.greaterOrEqualsThan, { number: 0.01 }))
        .validate();

const quantity = (value) =>
    notEmptyValidator(value)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(isInteger, tValidation(validation.mustBeInteger))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .validate();

const segment = (_, form, __, path) => {
    const effect = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    const { segments, haveSegmentsConstraint } = get(form, "definition.requirements.user") || {};

    return notEmptyValidator(effect)
        .must((e) => {
            return !isEmpty(e) && !isEmpty(e.segment);
        }, tValidation(validation.required))
        .must((e) => {
            return haveSegmentsConstraint === true && segments != null && segments[0] != null && segments[0].excluded?.some((s) => s === e.segment);
        }, tValidation(validation.segmentMustBeInExcludedConstraint))
        .must((e) => e.segment.startsWith("promotion"), tValidation(validation.segmentMustStartsWithPrefix, { prefix: "promotion:" }))
        .validate();
};

const oneIsRequired = (value, form, __, path) => {
    const { requiredValue, requiredQuantity } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(value)
        .must(
            () =>
                (Boolean(requiredValue) === false && Boolean(requiredQuantity) === true) ||
                (Boolean(requiredValue) === true && Boolean(requiredQuantity) === false),
            tValidation(validation.oneIsRequiredText)
        )
        .validate();
};

const atLeastOneRequirementIsRequired = (value, form, __, path) => {
    const { requiredValue, requiredQuantity } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(value)
        .must(() => Boolean(requiredValue) || Boolean(requiredQuantity), tValidation(validation.atLestOneRequirement))
        .validate();
};

const deliveryModifierValue = (value, form, _, path) => {
    const delivery = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new notEmptyValidator(value)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(
            greaterOrEqualsThan(0),
            tValidation(validation.greaterOrEqualsThan, {
                number: 0,
            })
        )
        .when(delivery.type === "newDeliveryCost")
        .must(
            greaterOrEqualsThan(0.01),
            tValidation(validation.greaterOrEqualsThan, {
                number: 0.01,
            })
        )
        .when(delivery.type === "deliveryDiscount")
        .validate();
};

export const validators = {
    productId,
    mappedProductId,
    quantity,
    price,
    segment,
    oneIsRequired,
    atLeastOneRequirementIsRequired,
    deliveryModifierValue,
};
