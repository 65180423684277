import qs from "query-string";
import { useDebouncedCallback } from "use-debounce";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Add as AddIcon, Cached as RefreshIcon } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { UseMutationResult } from "@tanstack/react-query";
import moment from "moment";
import { common } from "translations";
import { isDate } from "utils-ts/functions";
import { useMessages, usePush, useTranslation } from "utils-ts/hooks";
import { FacetPagedCriteria, ItemsTableParams, PagedCriteria, SearchFacet } from "Shared";
import { Paths } from "routing-ts/ManagerPaths";
import { Button, ToggleButtonGroup } from "components-ts/controls";
import { AcceptRejectDialog } from "components-ts/dialogs";
import Facets from "components-ts/facets/Facets";
import { ActionColumn, Column, Facet, Table, TableHandlers, TableItem, TableLoadableProps, ValueColumn } from "components-ts/tables/table";
import { Spacing } from "components-ts/view";

type DataFilter<TParams extends FacetPagedCriteria | ItemsTableParams | PagedCriteria> = Partial<
    Omit<TParams, "pageSize" | "pageIndex" | "includeFacets" | "isActive">
>;
type ItemsTableProps<TModel extends TableItem, TParams extends FacetPagedCriteria | ItemsTableParams | PagedCriteria> = {
    items: TModel[];
    loadable: TableLoadableProps;
    columns: Column<TModel>[];
    formPath?: string;
    canAddItem?: boolean;
    hideToggle?: boolean;
    hideReload?: boolean;
    hidePagination?: boolean;
    saveFilters?: boolean;
    handleDoubleClick?: boolean;
    customFilter?: (initialParams: DataFilter<TParams>, onChangeFilter: (property: string, value: unknown) => void) => React.ReactNode;
    facets?: Facet<TParams>[];
    searchFacet?: SearchFacet[];
    facetPosition?: "top" | "left";
    defaultParams?: DataFilter<TParams> & { isActive: boolean };
    getItemId?: (item: TModel) => string;
    itemDeletionCustomText?: (item: TModel | undefined) => string;
    isCollapsed?: boolean;
} & (
    | {
          removeHook: (id?: string | undefined) => UseMutationResult<void, Response, {}, unknown>;
          getItemRemoveName?: (item: TModel) => string;
      }
    | {
          removeHook?: never;
          getItemRemoveName?: never;
      }
);

type SessionFilter<TParams extends FacetPagedCriteria | ItemsTableParams | PagedCriteria> = {
    page: string;
    filterState: TParams;
};

const ItemsTable = <TModel extends TableItem, TParams extends FacetPagedCriteria | ItemsTableParams | PagedCriteria>({
    items,
    loadable,
    columns,
    formPath,
    canAddItem = true,
    hideToggle = false,
    hideReload = false,
    hidePagination = false,
    saveFilters = true,
    handleDoubleClick = true,
    customFilter = undefined,
    facets = undefined,
    facetPosition = "left",
    defaultParams = undefined,
    removeHook = undefined,
    getItemId = undefined,
    itemDeletionCustomText = undefined,
    isCollapsed = false,
    searchFacet,
    getItemRemoveName = undefined,
}: ItemsTableProps<TModel, TParams>) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { push } = usePush();
    const { showSuccessMessage, showErrorMessage, showInfoMessage } = useMessages();
    const [itemToRemove, setItemToRemove] = React.useState<TModel | undefined>(undefined);

    let initialParams: TParams | undefined = undefined;
    const removeMutation = removeHook ? removeHook(itemToRemove && getItemId ? getItemId(itemToRemove) : itemToRemove?.id?.toString()) : undefined;

    const sessionFilterData = sessionStorage.getItem("dataListFilter");
    if (sessionFilterData) {
        const sessionFilter = JSON.parse(sessionFilterData) as SessionFilter<TParams>;
        if (sessionFilter.page === location.pathname) {
            initialParams = sessionFilter.filterState;
        }
    }
    const parsedQuery = qs.parse(location.search) as unknown as TParams;
    const defaultTableParams: TParams = {
        includeFacets: (facets || []).length > 0 ? true : undefined,
        isActive: !hideToggle ? true : undefined,
        ...defaultParams,
        pageIndex: 1,
        pageSize: localStorage.getItem("tablePagination") !== null ? JSON.parse(localStorage.getItem("tablePagination") as string) : 10,
    } as unknown as TParams;

    if (!initialParams) {
        if (Object.keys(parsedQuery).length > 0) {
            initialParams = parsedQuery;
        } else {
            initialParams = defaultTableParams as TParams;
        }
    }

    const [pageIndex, setPageIndex] = React.useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(parseInt(localStorage.getItem("tablePagination") || "10"));
    const [filteredItems, setFilteredItems] = React.useState<TModel[]>(items);
    const [filters, setFilters] = React.useState<TModel>();
    const [data, setData] = React.useState<TModel[]>(filteredItems ? filteredItems?.slice(0, itemsPerPage) : []);

    useEffect(() => {
        if (filters) {
            setFilteredItems(
                items?.filter((item, index) => {
                    return Object.entries(filters).every(([key, value]) => {
                        if (value === undefined || value === null) return true;

                        const column = columns.find((col) => (col as ValueColumn<TModel>).property === key) as ValueColumn<TModel>;
                        let itemValue = item[key as keyof TModel] as TModel[keyof TModel];

                        if (column && column.as && typeof column.as === "function") {
                            itemValue = column.as(null, item, index) as TModel[keyof TModel];
                        }

                        if (
                            column.as === "dateTime" &&
                            column.filterAs === "date" &&
                            (moment.isMoment(itemValue) || isDate(itemValue)) &&
                            (moment.isMoment(value) || isDate(value))
                        ) {
                            const itemValueMoment = moment.isMoment(itemValue) ? itemValue : moment(itemValue);
                            const valueMoment = moment.isMoment(value) ? value : moment(value);
                            return itemValueMoment.isSame(valueMoment, "day");
                        }

                        return itemValue && itemValue.toString().toLowerCase().includes(value.toString().toLowerCase());
                    });
                })
            );
        } else {
            setFilteredItems(items);
        }
    }, [items, filters]);

    useEffect(() => {
        if (items) {
            const startIndex = (pageIndex - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            setData(filteredItems?.slice(startIndex, endIndex));
        }
    }, [pageIndex, itemsPerPage, filteredItems]);

    useEffect(() => {
        setFilters({
            ...filters,
            ...columns
                ?.filter((c) => (c as ValueColumn<TModel>) !== null && (c as ValueColumn<TModel>).property)
                ?.map((c) => (c as ValueColumn<TModel>).property.toString())
                ?.reduce((accumulator, current) => {
                    if (!current) {
                        return accumulator;
                    } else {
                        return {
                            ...accumulator,
                            [current.toString()]: initialParams && hasObjectKey(initialParams, current) ? initialParams[current] : undefined,
                        };
                    }
                }, {} as TModel),
        } as TModel);
    }, [columns]);

    const updateFilters = (property: keyof TModel, value: unknown) => {
        setFilters({
            ...filters,
            [property.toString()]: value,
        } as TModel);
        setPageIndex(1);

        if (saveFilters) {
            updateSessionParams(property.toString(), value);
        }
    };
    const updateFilter = useDebouncedCallback(updateFilters, 500);

    const updateSessionParams = (property: string, value: unknown) => {
        let sessionFilter: SessionFilter<TParams> | undefined = undefined;
        const sessionFilterData = sessionStorage.getItem("dataListFilter");
        if (sessionFilterData) {
            const lastSessionFilter = JSON.parse(sessionFilterData) as SessionFilter<TParams>;
            if (lastSessionFilter.page === location.pathname) {
                sessionFilter = lastSessionFilter;
            }
        }

        if (sessionFilter) {
            sessionFilter.filterState = {
                ...sessionFilter.filterState,
                [property]: value,
            };
        } else {
            sessionFilter = {
                page: location.pathname,
                filterState: {
                    [property]: value,
                    ...defaultTableParams,
                } as TParams,
            };
        }

        sessionStorage.setItem("dataListFilter", JSON.stringify(sessionFilter));
    };

    const updateCustomParams = (property: string, value: unknown) => {
        const newValue = value === "" ? undefined : value;

        setFilters({
            ...filters,
            [property.toString()]: newValue,
        } as TModel);

        setPageIndex(1);

        if (saveFilters) {
            updateSessionParams(property, value);
        }
    };

    const tableHandlers: TableHandlers<TModel> = {
        onFilterChange: columns.some((c) => (c as ValueColumn<TModel>).filtrable) ? updateFilter : undefined,
    };

    if (handleDoubleClick && formPath) {
        tableHandlers.onRowDoubleClick = (item: TModel) => {
            if (formPath) {
                push(
                    Paths.GeneratePath(formPath, {
                        id: getItemId ? getItemId(item) : item.id,
                    })
                );
            }
        };
    }

    const removeItem = async () => {
        if (removeMutation) {
            await removeMutation.mutateAsync(
                {},
                {
                    onSuccess: () => {
                        showSuccessMessage("Pomyślnie usunięto");
                    },
                    onError: (error) => {
                        showErrorMessage("Błąd przy usuwaniu");
                        console.error(error);
                    },
                }
            );

            setItemToRemove(undefined);
            loadable.refetch();
        } else {
            throw new Error("Missing removeMutation");
        }
    };

    function hasObjectKey<T extends {}>(obj: T, key: PropertyKey): key is keyof T {
        return key in obj;
    }

    const isAction = <T extends TableItem>(column: ActionColumn<T> | ValueColumn<T>): column is ActionColumn<T> => {
        return (column as ActionColumn<T>).actionType !== undefined;
    };

    const table = (
        <Table
            columns={
                columns.some((c) => isAction(c) && c.actionType === "delete" && c.onClick === undefined)
                    ? columns.map((c) =>
                          isAction(c) && c.actionType === "delete" && c.onClick === undefined
                              ? { ...c, onClick: (item: TModel) => setItemToRemove(item) }
                              : c
                      )
                    : columns
            }
            items={data}
            pagination={
                !hidePagination
                    ? {
                          pageIndex: pageIndex,
                          pageSize: itemsPerPage,
                          totalCount: filteredItems?.length || 0,
                          onPageChange: (pageIndex) => {
                              setPageIndex(pageIndex);
                          },
                          onPageSizeChange: (pageSize) => {
                              localStorage.setItem("tablePagination", pageSize.toString());
                              setPageIndex(1);
                              setItemsPerPage(pageSize);
                          },
                      }
                    : undefined
            }
            loadable={loadable}
            handlers={tableHandlers}
            initialFilters={columns
                .filter((c) => (c as ValueColumn<TModel>) !== null && (c as ValueColumn<TModel>).property)
                .map((c) => (c as ValueColumn<TModel>).property.toString())
                .reduce((accumulator, current) => {
                    if (!current) {
                        return accumulator;
                    } else {
                        return {
                            ...accumulator,
                            [current.toString()]: initialParams && hasObjectKey(initialParams, current) ? initialParams[current] : undefined,
                        };
                    }
                }, {} as TModel)}
        />
    );

    const facet =
        facets && facets.length > 0 ? (
            <Facets
                facets={facets}
                initialParams={initialParams}
                updateQueryParams={updateCustomParams}
                searchFacets={loadable.status === "success" && !!searchFacet ? searchFacet : []}
                orientation={facetPosition === "left" ? "vertical" : "horizontal"}
            />
        ) : undefined;
    const hasIsActiveProperty = items?.some((item) => typeof item.isActive === "boolean");
    if (isCollapsed) {
        return <></>;
    }
    return (
        <>
            {(!hideToggle || canAddItem || !hideReload || customFilter || (facet && facetPosition === "top")) && (
                <Spacing spacing={1}>
                    <>
                        {customFilter && customFilter(initialParams, updateCustomParams)}

                        {(!hideToggle || canAddItem || !hideReload) && (
                            <Grid container>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Grid
                                        spacing={1}
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ display: "inline-flex" }}
                                    >
                                        {hasIsActiveProperty && !hideToggle && (
                                            <Grid item>
                                                <ToggleButtonGroup<string>
                                                    value={filters && "isActive" in filters ? String(filters?.isActive) : "undefined"}
                                                    items={[
                                                        {
                                                            name: t(common.active),
                                                            value: "true",
                                                        },
                                                        {
                                                            name: t(common.inActive),
                                                            value: "false",
                                                        },
                                                        {
                                                            name: t(common.all),
                                                            value: "undefined",
                                                        },
                                                    ]}
                                                    onChange={(value) => {
                                                        let isActive;
                                                        if (value === "true") {
                                                            isActive = true;
                                                        }
                                                        if (value === "false") {
                                                            isActive = false;
                                                        }

                                                        updateFilter("isActive", isActive);
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Grid
                                        spacing={1}
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        style={{ display: "inline-flex" }}
                                    >
                                        {!hideReload && (
                                            <Grid item>
                                                <Button
                                                    startIcon={<RefreshIcon />}
                                                    color="primary"
                                                    label="reload"
                                                    isLoading={loadable.isRefetching}
                                                    onClick={() => {
                                                        loadable.refetch();
                                                    }}
                                                />
                                            </Grid>
                                        )}

                                        {canAddItem && (
                                            <Grid item>
                                                <Button
                                                    startIcon={<AddIcon />}
                                                    color="primary"
                                                    label="addNew"
                                                    onClick={() => {
                                                        if (!formPath) {
                                                            return;
                                                        }

                                                        push(formPath);
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {facet && facetPosition === "top" && (
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    {facet}
                                </Grid>
                            </Grid>
                        )}
                    </>
                </Spacing>
            )}
            <Spacing spacing={1}>
                {facet && facetPosition === "left" ? (
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={2}
                        >
                            {facet}
                        </Grid>
                        <Grid
                            item
                            xs={10}
                        >
                            {table}
                        </Grid>
                    </Grid>
                ) : (
                    table
                )}
            </Spacing>

            <AcceptRejectDialog
                isOpen={itemToRemove !== undefined}
                onAccept={() => {
                    showInfoMessage("Usuwanie");
                    removeItem();
                }}
                onReject={() => {
                    setItemToRemove(undefined);
                }}
                dialogTitle={t(common.remove)}
            >
                <Typography variant="body1">
                    {itemDeletionCustomText
                        ? itemDeletionCustomText(itemToRemove)
                        : t(common.areYouSure, {
                              what: `${t(common.toRemove)} ${
                                  itemToRemove !== undefined
                                      ? getItemRemoveName
                                          ? getItemRemoveName(itemToRemove)
                                          : itemToRemove?.name
                                      : "please let know IT how to make it"
                              }`,
                          })}
                    {}
                </Typography>
            </AcceptRejectDialog>
        </>
    );
};

export default ItemsTable;
