import * as productScans from "./productScans";
import * as productSubstitutes from "./productSubstitutes";
import * as shopConfigurations from "./shopConfigurations";
import * as summaryOfShopScan from "./summaryOfShopScan";

const crawler = {
    productSubstitutes,
    shopConfigurations,
    productScans,
    summaryOfShopScan,
};

export default crawler;
