import { generatePath } from "react-router-dom";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";
import { ProductSubstitute, ProductSubstituteListResponse, ProductSubstitutePageRequest } from "Crawler";

const queryKey = "api/product-substitute/";

const url = (id?: string) => generatePath(`${queryKey}:id?`, { id: id ? id : null });

export const useFindProductSubstitutes = (initialParams: ProductSubstitutePageRequest) => {
    return useGetPagedQuery<ProductSubstituteListResponse, ProductSubstitutePageRequest>({
        app: "crawler",
        url: url(),
        queryParams: initialParams,
    });
};

export const useGetProductSubstitute = (id?: string) => {
    return useGetQuery<ProductSubstitute>({
        app: "crawler",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useUpdateProductSubstitute = (id?: string) => {
    return useCreateOrUpdateMutation<ProductSubstitute, ProductSubstitute>({
        app: "crawler",
        url: url(id),
        id: id,
    });
};
