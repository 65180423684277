import { makeStyles } from "tss-react/mui";
import React from "react";
import { default as ReactSignaturePad } from "react-signature-canvas";
import { Grid, Icon, Typography } from "@mui/material";
import { ValidationError } from "control-types";
import { IconButton } from "../buttons";
import FormHelperText from "./FormHelperText";

export type SignaturePadType = {
    value: string;
    label: string;
    /** signature in Base64 */
    onChange: (signature: string, type: string) => void;
    error?: ValidationError;
    readOnly?: boolean;
    width?: "standard" | "double" | "triple" | number;
};

const useStyles = makeStyles()((theme) => ({
    label: {
        padding: 0,
        fontSize: "0.8rem",
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: "0.00938em",
    },
    saveLabel: {
        color: theme.palette.warning.main,
    },
}));

const SignaturePad = ({ value, onChange, error, label, readOnly, width = "standard" }: SignaturePadType) => {
    const { cx, classes } = useStyles();
    const [padValue, setPadValue] = React.useState<string>(value);
    const ref = React.useRef<ReactSignaturePad>(null);
    const widthMultiplier = width === "double" ? 2 : width === "triple" ? 3 : 1;

    const [init, setInit] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (ref.current && value && !init) {
            if (value.startsWith("data:")) {
                ref.current.fromDataURL(value);
            } else {
                ref.current.fromDataURL(`data:image/png;base64,${value}`);
            }
            setInit(true);
        }
    }, [ref.current, value]);

    return (
        <Grid item>
            {label && (
                <Typography
                    component="div"
                    className={classes.label}
                    variant="caption"
                >
                    {label}
                </Typography>
            )}

            {readOnly ? (
                <img
                    style={{ width: typeof width === "number" ? width - 5 : `${280 * widthMultiplier}px`, height: "200px", border: "#000 1px solid" }}
                    src={value?.startsWith("data:") ? value : `data:image/png;base64,${value}`}
                    alt="Signature image"
                />
            ) : (
                <div
                    style={{
                        width: typeof width === "number" ? width - 5 : `${280 * widthMultiplier}px`,
                        height: "200px",
                        border: "#000 1px solid",
                        position: "relative",
                    }}
                >
                    <ReactSignaturePad
                        ref={ref}
                        canvasProps={{ style: { width: "100%", height: "100%" } }}
                        onEnd={(_) => {
                            if (ref.current) {
                                const signature = ref.current.toDataURL();
                                setPadValue(signature.split(",")[1]);
                            }
                        }}
                    />
                    <div style={{ position: "absolute", top: "3px", right: "3px" }}>
                        <IconButton
                            icon="clear"
                            onClick={() => {
                                if (ref.current) {
                                    ref.current.clear();
                                    onChange("", "image/png");
                                    setPadValue("");
                                }
                            }}
                            showInline
                            useWrapper={false}
                        />

                        <IconButton
                            icon="check"
                            onClick={() => {
                                if (ref.current) {
                                    const signature = ref.current.toDataURL();
                                    onChange(signature.split(",")[1], "image/png");
                                    setPadValue(signature.split(",")[1]);
                                }
                            }}
                            showInline
                            useWrapper={false}
                        />
                    </div>
                </div>
            )}

            {!readOnly && padValue !== value && (
                <Typography
                    component="div"
                    className={cx(classes.label, classes.saveLabel)}
                    variant="caption"
                >
                    Podpis nie został zapisany, w celu zapisania podpisu należy kliknąć przycisk <Icon>check</Icon>
                </Typography>
            )}

            <FormHelperText error={error} />
        </Grid>
    );
};

export default SignaturePad;
