import type { GetVendorSettlementReport, GetVendorTurnoverReport } from "Operations-VendorsReports";
import { useFileQuery } from "api/hooks";

export const useGetVendorSettlementReport = (params: GetVendorSettlementReport, isSubmitted: boolean) =>
    useFileQuery({
        app: "operations",
        url: "api/vendors/reports/settlement-base",
        fileName: "Raport rozliczeń dostawców",
        queryParams: params,
        shouldMakeRequest: isSubmitted,
    });

export const useGetVendorTurnoverReport = (params: GetVendorTurnoverReport, isSubmitted: boolean) =>
    useFileQuery({
        app: "operations",
        url: "api/vendors/reports/turnover",
        fileName: "Raport obrotów dostawców",
        queryParams: params,
        shouldMakeRequest: isSubmitted,
    });
