import { promotionTypes } from "components/Controls/promotionTypes";

export class PoolModifierEffect {
    constructor(
        poolModifier = {
            modifier: {
                discountPercent: 1,
            },
        }
    ) {
        const { modifier, newValue } = poolModifier;
        if (modifier) {
            this.poolModifier = {
                type: "modifier",
                modifier: new Modifier(modifier),
            };
        } else {
            this.poolModifier = {
                type: "newValue",
                newValue: newValue,
            };
        }
    }

    effectType = "poolModifier";
}

export class Modifier {
    constructor(modifier = { discountPercent: 1 }, progressiveQuantity) {
        const { discountPercent, discountValue, newPrice } = modifier;
        this.type = discountPercent ? "discountPercent" : discountValue ? "discountValue" : "newPrice";
        if (discountPercent) this.discountPercent = discountPercent;
        if (discountValue) this.discountValue = discountValue;
        if (newPrice) this.newPrice = newPrice;

        this.progressiveQuantity = progressiveQuantity;
    }
}

export class CartModifierEffect {
    constructor(
        cart = {
            includeProducts: {},
            quantity: 1,
            modifier: {
                type: "discountPercent",
                discountPercent: 1,
            },
        }
    ) {
        const { modifier } = cart;

        this.cartModifier = {
            includeProducts: cart.includeProducts,
            quantity: cart.quantity,
            type: "modifier",
            modifier: new Modifier(modifier),
        };
    }

    effectType = "cartModifier";
}

export class ProgressiveCartModifierEffect {
    constructor(
        cart = {
            includeProducts: {},
            quantity: 1,
            modifier: {
                discountPercent: 1,
            },
        },
        progressiveQuantities
    ) {
        const { progressiveModifiers } = cart;
        this.progressive = {
            includeProducts: cart.includeProducts,
            excludeProducts: cart.excludeProducts,
            quantity: cart.quantity,
            type: "progressiveModifiers",
            progressiveModifiers: progressiveModifiers.map((m, i) => new Modifier(m, progressiveQuantities[i])),
        };
    }

    effectType = "progressive";
}

export class NewProductEffect {
    constructor(newProduct = { price: 0.01 }, choiceKey) {
        this.choiceKey = choiceKey;
        this.newProduct = {
            productId: newProduct.productId,
            quantity: newProduct.quantity,
            price: newProduct.price,
            mappedProductIds: getMappedProductIds(newProduct),
        };
    }
    effectType = "newProduct";
}

const getMappedProductIds = (obj) => {
    if (obj.mappedProductIds?.length > 0) {
        return obj.mappedProductIds;
    } else if (obj.mappedProductId) {
        return [obj.mappedProductId];
    } else {
        return null;
    }
};

export class GratisEffect {
    constructor(newProduct = { price: 0.1 }) {
        this.newProduct = {
            productId: newProduct.productId,
            quantity: newProduct.quantity,
            price: newProduct.price,
            mappedProductIds: getMappedProductIds(newProduct),
        };
    }
    effectType = "newProduct";
    promotionType = "gratis";
}

export class SampleProductEffect {
    constructor(sampleProduct = {}) {
        this.sampleProduct = {
            productId: sampleProduct.productId,
            quantity: sampleProduct.quantity,
            isHidden: sampleProduct.isHidden,
            mappedProductIds: getMappedProductIds(sampleProduct),
        };
    }
    effectType = "sampleProduct";
}
export class PackagingModifierEffect {
    effectType = "packagingModifier";
}
export class DeliveryModifierEffect {
    constructor(
        deliveryModifier = {
            deliveryDiscount: 1,
        }
    ) {
        const { deliveryDiscount, newDeliveryCost } = deliveryModifier;
        if (deliveryDiscount || deliveryDiscount === 0) {
            this.deliveryModifier = {
                type: "deliveryDiscount",
                deliveryDiscount: deliveryDiscount,
            };
        }
        if (newDeliveryCost || newDeliveryCost === 0) {
            this.deliveryModifier = {
                type: "newDeliveryCost",
                newDeliveryCost: newDeliveryCost,
            };
        }
    }

    effectType = "deliveryModifier";
}
export class MembershipPointsEffect {
    constructor(membershipPoints = {}) {
        const { recencyPoints, monetaryPoints, bonusPoints = 1 } = membershipPoints;
        if (bonusPoints || bonusPoints === 0) {
            this.membershipPoints = {
                type: "bonusPoints",
                bonusPoints: bonusPoints,
            };
        }
        if (recencyPoints || recencyPoints === 0) {
            this.membershipPoints = {
                type: "recencyPoints",
                recencyPoints: recencyPoints,
            };
        }
        if (monetaryPoints || monetaryPoints === 0) {
            this.membershipPoints = {
                type: "monetaryPoints",
                monetaryPoints: monetaryPoints,
            };
        }
    }
    effectType = "membershipPoints";
}
export class DiscountModifierEffect {
    constructor(
        discountModifier = {
            modifier: {
                discountPercent: 1,
            },
        }
    ) {
        const { modifier, newValue } = discountModifier;
        if (modifier) {
            this.discountModifier = {
                type: "modifier",
                modifier: new Modifier(modifier),
            };
        } else {
            this.discountModifier = {
                type: "newValue",
                newValue: newValue,
            };
        }
    }

    effectType = "discountModifier";
}
export class SegmentEffect {
    constructor(segment = {}) {
        this.segment = {
            segment: segment.segment,
        };
    }
    effectType = "segment";
}

export function determineEffectType(effect, promotionType, progressiveQuantities) {
    const {
        poolModifier,
        cartModifier,
        newProduct,
        sampleProduct,
        deliveryModifier,
        membershipPoints,
        discountModifier,
        segment,
        choiceKey,
        chosenProductModifier,
        expiringSegment,
        generatorActivation,
    } = effect;
    if (poolModifier) return new PoolModifierEffect(poolModifier);
    if (cartModifier) {
        if (cartModifier.progressiveModifiers) {
            return new ProgressiveCartModifierEffect(cartModifier, progressiveQuantities);
        }

        return new CartModifierEffect(cartModifier, progressiveQuantities);
    }
    if (newProduct) {
        if (promotionType === promotionTypes.Gratis) {
            return new GratisEffect(newProduct);
        }
        return new NewProductEffect(newProduct, choiceKey);
    }
    if (sampleProduct) return new SampleProductEffect(sampleProduct);
    if (deliveryModifier) return new DeliveryModifierEffect(deliveryModifier);
    if (membershipPoints) return new MembershipPointsEffect(membershipPoints);
    if (discountModifier) return new DiscountModifierEffect(discountModifier);
    if (segment) return new SegmentEffect(segment);
    if (chosenProductModifier) return new ChosenProductModifier(chosenProductModifier);
    if (expiringSegment) return new ExpiringSegment(expiringSegment);
    if (generatorActivation) return new GeneratorActivation(generatorActivation);

    throw new Error("Promotion effect is missing");
}

export class ChosenProductModifier {
    constructor(
        chosenProductModifier = {
            modifier: {
                discountPercent: 1,
            },
        }
    ) {
        const { modifier } = chosenProductModifier;
        if (modifier) {
            this.chosenProductModifier = {
                modifier: new Modifier(modifier),
            };
        }
    }

    effectType = "chosenProductModifier";
}

export class Pool {
    constructor(pool = {}) {
        this.includeProducts = pool.includeProducts;
        this.excludeProducts = pool.excludeProducts;
        this.requiredValue = pool.requiredValue;
        this.requiredQuantity = pool.requiredQuantity;
    }
}

export class ExpiringSegment {
    constructor(expiringSegment = {}) {
        this.expiringSegment = expiringSegment;
    }

    effectType = "expiringSegment";
}

export class GeneratorActivation {
    constructor(generatorActivation = {}) {
        this.generatorActivation = generatorActivation;
    }

    effectType = "generatorActivation";
}
