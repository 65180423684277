import { FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { withFormName } from "utils/hoc";
import { useFormValueSelector } from "utils/hooks";
import React from "react";

const typesOfValue = [
    { value: "Percent", name: "Wartość procentowa", prefix: "%" },
    { value: "Money", name: "Wartość pieniężna", prefix: "PLN" },
];

const unitsDiff = (values) => {
    if (values.proposal != values.current) {
        return (values.current === "Money" ? "PLN" : "%") + "➨" + (values.proposal === "Money" ? "PLN" : "%");
    } else {
        return values.current === "Money" ? "PLN" : "%";
    }
};

const ValueWithType = ({
    names = {
        value: "value",
        typeOfValue: "typeOfValue",
    },
    labels = {
        value: "value",
        typeOfValue: "typeOfValue",
    },
    validates = {
        value: undefined,
        typeOfValue: undefined,
    },
    useDiff,
    readOnly,
    form,
    sectionPrefix,
    hideDefaultItem,
}) => {
    const typeOfValue = useFormValueSelector(form, [sectionPrefix, names.typeOfValue].filter(Boolean).join("."), typesOfValue[0].value);

    return (
        <>
            <FormField
                useDiff={useDiff}
                name={names.typeOfValue}
                label={labels.typeOfValue}
                type="select"
                validate={validates.typeOfValue}
                items={typesOfValue}
                readOnly={readOnly}
                hideDefaultItem={hideDefaultItem}
            />

            <FormField
                useDiff={useDiff}
                name={names.value}
                label={labels.value}
                type="text"
                format={decimalNormalize}
                validate={validates.value}
                adornment={{
                    position: "end",
                    value: typesOfValue.find((t) => t.value == typeOfValue)?.prefix ?? unitsDiff(typeOfValue),
                }}
                readOnly={readOnly}
            />
        </>
    );
};

export default withFormName(ValueWithType);
