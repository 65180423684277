import { Button, IconButton } from "components/Button";
import ArrayValidationError from "components/Controls/ArrayValidationError";
import { FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import { FormName, FormSection } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { toSelectList } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useChange } from "utils/hooks";
import { useCommonTranslation } from "utils/hooks";
import { Grid, Paper } from "@mui/material";
import { promotionTypes } from "../promotionTypes";
import Cart from "./Cart";
import ChosenProductModifier from "./ChosenProductModifier";
import DeliveryModifier from "./DeliveryModifier";
import DiscountModifier from "./DiscountModifier";
import ExpiringSegment from "./ExpiringSegment";
import GeneratorActivation from "./GeneratorActivation";
import MembershipPoints from "./MembershipPoints";
import NewProduct from "./NewProduct";
import PoolModifier from "./PoolModifier";
import SampleProduct from "./SampleProduct";
import Segment from "./Segment";
import { GratisEffect, MembershipPointsEffect, NewProductEffect, SampleProductEffect } from "./model";

const useStyles = makeStyles()((theme) => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
    },
    button: {
        marginLeft: "auto",
    },
}));

const ComponentSwitch = ({ effect, promotionType, ...props }) => {
    let effectType = effect.effectType;
    let emptyEffect = { effectType };
    switch (effect.effectType) {
        case "membershipPoints":
            emptyEffect.membershipPoints = effect.membershipPoints;
            return (
                <MembershipPoints
                    {...props}
                    effect={emptyEffect}
                />
            );
        case "deliveryModifier":
            emptyEffect.deliveryModifier = effect.deliveryModifier;
            return (
                <DeliveryModifier
                    {...props}
                    effect={emptyEffect}
                />
            );
        case "newProduct":
            emptyEffect.newProduct = effect.newProduct;
            return (
                <NewProduct
                    {...props}
                    effect={emptyEffect}
                />
            );
        case "cartModifier":
        case "progressive":
            emptyEffect.cartModifier = effect.cartModifier;
            return (
                <Cart
                    {...props}
                    effect={emptyEffect}
                />
            );
        case "sampleProduct":
            emptyEffect.sampleProduct = effect.sampleProduct;
            return (
                <SampleProduct
                    {...props}
                    effect={emptyEffect}
                />
            );
        case "discountModifier":
            emptyEffect.discountModifier = effect.discountModifier;
            return (
                <DiscountModifier
                    {...props}
                    effect={emptyEffect}
                />
            );
        case "poolModifier":
            emptyEffect.poolModifier = effect.poolModifier;
            return (
                <PoolModifier
                    {...props}
                    effect={emptyEffect}
                />
            );
        case "segment":
            emptyEffect.segment = effect.segment;
            return (
                <Segment
                    {...props}
                    effect={emptyEffect}
                />
            );
        case "chosenProductModifier":
            emptyEffect.PoolModifier = effect.modfier;
            return (
                <ChosenProductModifier
                    {...props}
                    effect={emptyEffect}
                />
            );
        case "expiringSegment":
            return (
                <ExpiringSegment
                    {...props}
                    effect={emptyEffect}
                />
            );
        case "generatorActivation":
            return (
                <GeneratorActivation
                    {...props}
                    effect={emptyEffect}
                />
            );
        default:
            throw Error("Unknown effect");
    }
};

const Effect = ({ fields, effects, promotionType, meta, form, sectionPrefix }) => {
    const change = useChange(form);
    const { classes } = useStyles();
    const { t, common } = useCommonTranslation();
    const effectTypes = toSelectList(
        [
            "poolModifier",
            "cartModifier",
            "newProduct",
            "sampleProduct",
            "deliveryModifier",
            "membershipPoints",
            "segment",
            "progressive",
            "chosenProductModifier",
            "expiringSegment",
            "generatorActivation",
        ],
        t
    );

    const isMultipleEffectsPromotion =
        promotionType === promotionTypes.Gratis ||
        promotionType === promotionTypes.PackGratis ||
        promotionType === promotionTypes.PoolGratis ||
        promotionType === promotionTypes.Sampling;

    let newDefaultEffect = new MembershipPointsEffect();
    if (isMultipleEffectsPromotion) {
        switch (promotionType) {
            case promotionTypes.Gratis:
                newDefaultEffect = new GratisEffect();
                break;
            case promotionTypes.PackGratis:
            case promotionTypes.PoolGratis:
                newDefaultEffect = new NewProductEffect();
                break;
            case promotionTypes.Sampling:
                newDefaultEffect = new SampleProductEffect();
                break;
            default:
                break;
        }
    }

    return (
        <Layout
            headerText={t(common.effect)}
            isEnlarged
        >
            <ArrayValidationError {...meta} />
            {fields.map((field, index) => {
                const effect = effects[index] || {};
                return (
                    <FormSection
                        key={field}
                        name={field}
                    >
                        <Paper
                            elevation={2}
                            className={classes.paper}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <FormField
                                        label={t(common.type)}
                                        disabled={Boolean(promotionType)}
                                        name="effectType"
                                        type="select"
                                        items={effectTypes}
                                        onChange={(_, value) => {
                                            change(sectionPrefix ? sectionPrefix + "." + field : field, {
                                                effectType: value,
                                                ...(value === "cartModifier"
                                                    ? {
                                                          cartModifier: {
                                                              type: "modifier",
                                                              modifier: {
                                                                  type: "discountPercent",
                                                                  discountPercent: 1,
                                                              },
                                                          },
                                                      }
                                                    : {}),
                                            });
                                        }}
                                        hideDefaultItem
                                    />
                                    {(!promotionType || (isMultipleEffectsPromotion && fields.length > 1)) && (
                                        <Grid
                                            item
                                            className={classes.button}
                                        >
                                            <IconButton
                                                icon="delete"
                                                onClick={() => fields.remove(index)}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <FormName>
                                        {(formName) => {
                                            return (
                                                <FormSection name={effect.effectType}>
                                                    <ComponentSwitch
                                                        effect={effect}
                                                        field={field}
                                                        formName={formName}
                                                        promotionType={promotionType}
                                                    />
                                                </FormSection>
                                            );
                                        }}
                                    </FormName>
                                </Grid>
                            </Grid>
                        </Paper>
                    </FormSection>
                );
            })}
            {!promotionType || isMultipleEffectsPromotion ? (
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid
                        item
                        className={classes.button}
                    >
                        <Button
                            actionType="primary"
                            onClick={() => fields.push(newDefaultEffect)}
                        >
                            {t(common.add)}
                        </Button>
                    </Grid>
                </Grid>
            ) : undefined}
        </Layout>
    );
};

export default withFormName(Effect);
