import administration from "./keys/administration";
import bridge from "./keys/bridge";
import commerceErrorCode from "./keys/commerceErrorCode";
import commerceProductProblems from "./keys/commerceProductProblems";
import common from "./keys/common";
import content from "./keys/content";
import crawler from "./keys/crawler";
import customerBalanceCommandNames from "./keys/customerBalanceCommandNames";
import customerDeposits from "./keys/customerDeposits";
import customers from "./keys/customers";
import documentArchive from "./keys/documentArchive";
import orders from "./keys/orders";
import payments from "./keys/payments";
import procedures from "./keys/procedures";
import roles from "./keys/roles";
import routing from "./keys/routing";
import segments from "./keys/segments";
import validation from "./keys/validation";
import vendor from "./keys/vendor";
import voucher from "./keys/voucher";
import voucherKinds from "./keys/voucherKinds";

export type TranslationObject = {
    /**
     * Key with namespace e.g. "vendor:dateFrom" or key from common namespace e.g. "id"
     */
    resource: string;
    params?: {};
};

export type Translation = TranslationObject | string;

export type TranslationFunction = (translation: Translation, params?: {}) => string;

export type Namespace =
    | "administration"
    | "bridge"
    | "commerceErrorCode"
    | "commerceProductProblems"
    | "common"
    | "content"
    | "crawler"
    | "customerBalanceCommandNames"
    | "customerDeposits"
    | "customers"
    | "documentArchive"
    | "orders"
    | "payments"
    | "procedures"
    | "roles"
    | "routing"
    | "segments"
    | "validation"
    | "vendor"
    | "voucher"
    | "voucherKinds";

const translation = {
    administration,
    bridge,
    commerceErrorCode,
    commerceProductProblems,
    common,
    content,
    crawler,
    customerBalanceCommandNames,
    customerDeposits,
    customers,
    documentArchive,
    orders,
    payments,
    procedures,
    roles,
    routing,
    segments,
    validation,
    vendor,
    voucher,
    voucherKinds,
} as const;

export const namespaces = Object.keys(translation).map((k) => k.toString());

export const namespacesTranslation = Object.keys(translation)
    .map((k) => ({
        namespace: k,
        keys: Object.keys(translation[k as Namespace]),
    }))
    .reduce<{ [key: string]: Array<string> }>((acc, cur) => ({ ...acc, [cur.namespace]: cur.keys }), {});

export type TransationResources = typeof translation;

export function isTranslationObject(translation: Translation | string): translation is TranslationObject {
    return (translation as TranslationObject).resource !== undefined;
}

const translationItems = Object.values({ ...translation }).map((k) => k.toString());

export function isTranslation(name: Translation | string): name is Translation {
    if (typeof name !== "string") {
        return translationItems.includes(name.resource);
    }

    return translationItems.includes(name as string) !== undefined;
}
