import { useDebounce } from "use-debounce";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { FieldPath } from "react-hook-form";
import { FilterList } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Grid, Tooltip } from "@mui/material";
import {
    GridActionsCellItem,
    GridActionsColDef,
    GridCallbackDetails,
    GridColDef,
    GridColumnResizeParams,
    GridColumnVisibilityModel,
    GridPaginationModel,
    GridRowSelectionModel,
    DataGrid as MuiDataGrid,
    gridClasses,
    useGridApiRef,
} from "@mui/x-data-grid";
import { GridApiCommunity, GridBaseColDef } from "@mui/x-data-grid/internals";
import { createId } from "@paralleldrive/cuid2";
import { DataGridColumn, DataGridProps } from "control-types";
import { useTranslation, useWindowSize } from "utils-ts/hooks";
import { Button, TextField, ToggleButton } from "components-ts/controls";
import { Spacing } from "components-ts/view";

const columnWidthsStorageName = "dataGridWidths";
const dataGridPaginationStorageName = "dataGridPagination";
const initState = {
    pagination: {
        paginationModel: {
            pageSize:
                localStorage.getItem(dataGridPaginationStorageName) !== null
                    ? JSON.parse(localStorage.getItem(dataGridPaginationStorageName) as string)
                    : 50,
        },
    },
};

const pageSizes = [10, 20, 50, 100];
const serverPageSizes = [10, 25, 50, 100];
const sx = {
    "& .MuiDataGrid-columnHeaderTitle": {
        whiteSpace: "normal",
        lineHeight: "normal",
    },
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#283593",
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeader.a": {
        backgroundColor: "#283593",
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeader.b": {
        backgroundColor: "#0e8e00",
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-sortIcon": {
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-menuIconButton": {
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-filterIcon": {
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
        color: "#FFFFFF",
        borderColor: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeaderCheckbox": {
        backgroundColor: "#283593",
        color: "#FFFFFF",
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: "#ececec",
    },
    "& ::-webkit-scrollbar": {
        width: "20px",
        height: "20px",
    },
};

const getRowClassName = (params: { indexRelativeToCurrentPage: number }) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd");

type DataGridFilterProps<TValue extends { id: string } & Record<string, unknown>> = {
    filterFields?: FieldPath<TValue>[];
    rows: TValue[];
    setFilteredRows: (filteredRows: TValue[]) => void;
};
const DataGridFilters = <TValue extends { id: string } & Record<string, unknown>>({
    rows,
    filterFields,
    setFilteredRows,
}: DataGridFilterProps<TValue>): JSX.Element => {
    const { t } = useTranslation();
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [filterValues, setFilterValues] = useState<Record<string, string>>({});
    const [debouncedFilterValues] = useDebounce(filterValues, 500);

    useEffect(() => {
        if (!isFilterOpen) {
            setFilteredRows(rows);
        } else {
            const filters = Object.entries(debouncedFilterValues)
                .filter(([, value]) => value.trim() !== "")
                .map(([name, value]) => ({
                    name,
                    values: value
                        .split(",")
                        .map((v) => v.trim())
                        .filter((v) => v !== ""),
                }));

            const filtered =
                filters.length === 0
                    ? rows
                    : rows.filter((row) =>
                          filters.every((filter) => {
                              const value = row[filter.name as keyof TValue];

                              if (value === null || value === undefined) {
                                  return false;
                              }

                              const valueType = typeof value;

                              if (valueType === "string") {
                                  return filter.values.some((filterValue) => (value as string).startsWith(filterValue));
                              } else if (valueType === "number") {
                                  return filter.values.some((filterValue) => {
                                      const numFilterValue = Number(filterValue);
                                      return !isNaN(numFilterValue) && value === numFilterValue;
                                  });
                              } else {
                                  return false;
                              }
                          })
                      );

            setFilteredRows(filtered);
        }
    }, [debouncedFilterValues, rows, isFilterOpen]);

    const fieldTypes = useMemo(() => {
        const types: Record<string, string> = {};
        if (rows.length > 0) {
            filterFields?.forEach((field) => {
                for (const row of rows) {
                    const value = row[field as keyof TValue];
                    if (value !== null && value !== undefined) {
                        types[field] = typeof value;
                        break;
                    }
                }
            });
        }
        return types;
    }, [rows, filterFields]);

    return (
        <>
            {filterFields && (
                <Spacing spacing={1}>
                    <ToggleButton
                        item={{ name: "Filtry", value: "filter", startIcon: <FilterList /> }}
                        onChange={(v) => {
                            setIsFilterOpen(v.value || false);
                        }}
                        value={"filter"}
                    />
                    {isFilterOpen && (
                        <Box>
                            <Spacing spacing={1}>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    justify-content="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    {filterFields?.map((field) => {
                                        const value = filterValues[field] || "";
                                        const fieldType = fieldTypes[field] || "string";

                                        return (
                                            <Tooltip
                                                key={field}
                                                title={t("multipleValuesByComma")}
                                            >
                                                <Grid
                                                    item
                                                    xs={"auto"}
                                                >
                                                    <TextField
                                                        label={t(field)}
                                                        value={value}
                                                        onChange={(newValue: string) => {
                                                            let newValueFiltered = newValue;
                                                            if (fieldType === "number") {
                                                                newValueFiltered = newValue.replace(/[^0-9,.]/g, "");
                                                            }
                                                            setFilterValues((prev) => ({
                                                                ...prev,
                                                                [field]: newValueFiltered,
                                                            }));
                                                        }}
                                                    />
                                                </Grid>
                                            </Tooltip>
                                        );
                                    })}
                                </Grid>
                            </Spacing>
                        </Box>
                    )}
                </Spacing>
            )}
        </>
    );
};

type DataGridWidth = {
    name: string;
    width: number;
};
const DataGrid = <TValue extends { id: string }>({
    columns,
    rows,
    visibleColumnsKey,
    addItem,
    getRowActions,
    checkboxSelection,
    canCopyItemValues,
    copyButtonText,
    deleteButtonText,
    handleCopyValues,
    onRowEditStop,
    setSelectedRows,
    additionalButtons,
    handlePaginationChange,
    paginationModel,
    isRowSelectable,
    columnHeaderHeight = 50,
    filterFields,
    handleDeleteRows,
}: DataGridProps<TValue>): JSX.Element => {
    const apiRef = useGridApiRef();
    const onColumnVisibilityModelChange = React.useMemo(
        () => (_model: GridColumnVisibilityModel, _details: GridCallbackDetails) => {
            const state = apiRef.current.exportState();
            localStorage.setItem(visibleColumnsKey, JSON.stringify(state));
        },
        [visibleColumnsKey]
    );
    const [selection, setSelection] = useState<GridRowSelectionModel>([]);
    const [filteredRows, setFilteredRows] = useState<TValue[]>(rows);
    const pageSize = paginationModel?.pageSize ?? initState.pagination.paginationModel.pageSize;
    const pageIndex = paginationModel?.pageIndex ?? 1;
    const columnWidths: DataGridWidth[] =
        localStorage.getItem(columnWidthsStorageName) !== null ? JSON.parse(localStorage.getItem(columnWidthsStorageName) as string) : [];

    useEffect(() => {
        try {
            const stateJSON = localStorage.getItem(visibleColumnsKey);
            if (stateJSON) {
                apiRef.current.restoreState(JSON.parse(stateJSON));
            }
        } catch (e) {
            console.log(e);
        }
    }, []);

    const handleAdd = () => {
        if (!addItem) {
            return;
        }

        addItem(createId());
    };

    const isGridColDef = (column: DataGridColumn<TValue> | GridColDef<TValue>): column is GridColDef<TValue> => {
        return (column as GridColDef<TValue>).field !== undefined;
    };

    const cols: GridColDef<TValue>[] = columns.map((c) => {
        const columnWidth = columnWidths.find((cn) => cn.name == c.name || cn.name == c.field);
        if (columnWidth) {
            c.width = columnWidth.width;
        }

        if (isGridColDef(c)) {
            return c;
        } else {
            return {
                field: c.name,
                headerName: c.label,
                width: c.width,
                minWidth: c.minWidth,
                editable: c.readOnly ? false : true,
                hideable: c.canHide ? true : false,
                headerClassName: c.alterStyle ? "b" : "a",
                renderCell: c.renderCell,
                renderEditCell: c.renderEditCell,
                display: "flex",
                description: c.description
            } as GridBaseColDef<TValue>;
        }
    });

    if (getRowActions) {
        cols.push({
            field: "actions",
            type: "actions",
            headerName: "",
            width: 100,
            hideable: false,
            sortable: false,
            cellClassName: "actions",
            headerClassName: "a",
            resizable: false,
            getActions: ({ id, row }: { id: string; row: TValue }) => {
                return getRowActions().map((ca) => (
                    <GridActionsCellItem
                        key={ca.actionType}
                        icon={ca.actionType === "delete" ? <DeleteIcon /> : <></>}
                        label={ca.actionType === "delete" ? "Delete" : ""}
                        onClick={() => ca.onClick(id, row)}
                        color="secondary"
                        disabled={ca.disabled}
                    />
                ));
            },
        } as GridActionsColDef<TValue>);
    }

    const handleSetSelection = (rows: GridRowSelectionModel) => {
        setSelection(rows);
        if (setSelectedRows) {
            setSelectedRows(rows);
        }
    };

    return (
        <>
            {filterFields && (
                <DataGridFilters
                    rows={rows}
                    filterFields={filterFields}
                    setFilteredRows={setFilteredRows}
                />
            )}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "90vh",
                    }}
                >
                    <MuiDataGrid
                        getRowHeight={() => "auto"}
                        editMode="row"
                        apiRef={apiRef}
                        rows={filterFields ? filteredRows : rows}
                        columns={cols}
                        initialState={initState}
                        pageSizeOptions={paginationModel ? serverPageSizes : pageSizes}
                        disableRowSelectionOnClick
                        showCellVerticalBorder
                        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                        getRowClassName={getRowClassName}
                        sx={sx}
                        checkboxSelection={checkboxSelection}
                        isRowSelectable={isRowSelectable}
                        onRowSelectionModelChange={handleSetSelection}
                        onRowEditStop={(params) => {
                            if (onRowEditStop) {
                                onRowEditStop(params.row);
                            }
                        }}
                        onPaginationModelChange={(model: GridPaginationModel) => {
                            localStorage.setItem(dataGridPaginationStorageName, model.pageSize.toString());
                            if (handlePaginationChange) {
                                handlePaginationChange({ pageIndex: model.page + 1, pageSize: model.pageSize });
                            }
                        }}
                        paginationModel={paginationModel ? { pageSize: pageSize, page: pageIndex - 1 } : undefined}
                        rowCount={paginationModel?.totalCount}
                        paginationMode={paginationModel ? "server" : "client"}
                        columnHeaderHeight={columnHeaderHeight}
                        onColumnWidthChange={(params: GridColumnResizeParams) => {
                            const col: DataGridWidth[] =
                                localStorage.getItem(columnWidthsStorageName) !== null
                                    ? JSON.parse(localStorage.getItem(columnWidthsStorageName) as string)
                                    : [];
                            localStorage.setItem(
                                columnWidthsStorageName,
                                JSON.stringify([
                                    ...col.filter((c) => c.name != params.colDef.field),
                                    { name: params.colDef.field, width: params.width },
                                ])
                            );
                        }}
                    />
                </Box>
            <Spacing spacing={2}>
                <Grid
                    container
                    direction="column"
                    justify-content="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    {addItem && (
                        <Grid
                            item
                            key="add"
                        >
                            <Button
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleAdd}
                                label="addRow"
                            />
                        </Grid>
                    )}
                    {canCopyItemValues && (
                        <Grid
                            item
                            key="copy"
                        >
                            <Button
                                color="primary"
                                onClick={() => {
                                    if (handleCopyValues) {
                                        handleCopyValues(selection, rows);
                                    }
                                }}
                                disabled={selection.length == 0}
                                label={copyButtonText || "copy"}
                                startIcon={<AutoAwesomeMotionIcon />}
                            />
                        </Grid>
                    )}
                    {handleDeleteRows && (
                        <Grid
                            item
                            key="delete"
                        >
                            <Button
                                color="primary"
                                onClick={() => {
                                    if (handleDeleteRows) {
                                        handleDeleteRows(selection, rows);
                                    }
                                }}
                                disabled={selection.length == 0}
                                label={deleteButtonText || "delete"}
                                startIcon={<DeleteForeverIcon />}
                            />
                        </Grid>
                    )}
                    {!!additionalButtons &&
                        additionalButtons.map((button, index) => (
                            <Grid
                                item
                                key={`button-${index}`}
                            >
                                {button}
                            </Grid>
                        ))}
                </Grid>
            </Spacing>
        </>
    );
};

export default DataGrid;

const DataGridContext = createContext<React.MutableRefObject<GridApiCommunity> | undefined>(undefined);

export const useDataGridApiContext = (): React.MutableRefObject<GridApiCommunity> => {
    const context = useContext(DataGridContext);
    if (!context) {
        throw new Error("useDataGridApiContext must be used within a DataGridApiProvider");
    }

    return context;
};

export const DataGridApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const apiRef = useGridApiRef();

    return <DataGridContext.Provider value={apiRef}>{children}</DataGridContext.Provider>;
};
