const vendor = {
    priceList: "priceList",
    vendor: "vendor",
    producer: "producer",
    brand: "brand",
    dateFrom: "dateFrom",
    dateTo: "dateTo",
    downloadPriceList: "downloadPriceList",
    downloadPriceListPromo: "downloadPriceListPromo",
    downloadPriceListExcel: "downloadPriceListExcel",
    showPriceLists: "showPriceLists",
    productId: "productId",
    ean: "ean",
    productName: "productName",
    actualBuyPrice: "actualBuyPrice",
    actualSellPrice: "actualSellPrice",
    margin: "margin",
    averageHEL: "averageHEL",
    from: "from",
    to: "to",
    price100: "price100",
    discountBasic: "discountBasic",
    discountPromo: "discountPromo",
    lastModification: "lastModification",
    sellPrice: "sellPrice",
    clearButtonText: "clearButtonText",
    emptyVendorResult: "emptyVendorResult",
    emptyProducerResult: "emptyProducerResult",
    emptyBrandResult: "emptyBrandResult",
    importPromotionFile: "importPromotionFile",
    downloadPromotionFileModel: "downloadPromotionFileModel",
    import: "import",
    saveAllSellPrices: "saveAllSellPrices",
    confirmChangesModalText: "confirmChangesModalText",
    confirmChangesModalConfirmText: "confirmChangesModalConfirmText",
    confirmChangesModalTitle: "confirmChangesModalTitle",
    productData: "productData",
    productClasification: "productClasification",
    productStatus: "productStatus",
    categoryManager: "categoryManager",
    stock: "stock",
    availableVendors: "availableVendors",
    Active: "Active",
    ActiveToWithdraw: "ActiveToWithdraw",
    ActiveTemporarilyInaccessible: "ActiveTemporarilyInaccessible",
    Withdrawn: "Withdrawn",
    sellHistory: "sellHistory",
    sellDate: "sellDate",
    quantity: "quantity",
    grossTotal: "grossTotal",
    netTotal: "netTotal",
    discountHistory: "discountHistory",
    discountType: "discountType",
    discountValue: "discountValue",
    sellPriceHistory: "sellPriceHistory",
    purchasePriceHistory: "purchasePriceHistory",
    vendorId: "vendorId",
    vendorName: "vendorName",
    cooperationType: "cooperationType",
    dateOfEndCooperation: "dateOfEndCooperation",
    dateOfStartCooperation: "dateOfStartCooperation",
    Mails: "Mails",
    creditLimit: "creditLimit",
    creditLimitValidFrom: "creditLimitValidFrom",
    creditLimitValidTo: "creditLimitValidTo",
    contactData: "contactData",
    personName: "personName",
    jobPosition: "jobPosition",
    invoiceIssueReason: "invoiceIssueReason",
    typeOfContact: "typeOfContact",
    email: "email",
    phoneNumber: "phoneNumber",
    productCategories: "productCategories",
    contracts: "contracts",
    sg: "sg",
    refundations: "refundations",
    bonusType: "bonusType",
    percentOfRotation: "percentOfRotation",
    valueForPeriod: "valueForPeriod",
    paymentTermForProductFrom: "paymentTermForProductFrom",
    paymentTermsForProducts: "paymentTermsForProducts",
    termsOfBusiness: "termsOfBusiness",
    typeOfTermBusiness: "typeOfTermBusiness",
    paymentTermsForMarketingInvoice: "paymentTermsForMarketingInvoice",
    vendorData: "vendorData",
    acceptChanges: "acceptChanges",
    rejectChanges: "rejectChanges",
    addContract: "addContract",
    addTermOfBusiness: "addTermOfBusiness",
    waitingForConfirmgChanges: "waitingForConfirmgChanges",
    noPendingChanges: "noPendingChanges",
    newContract: "newContract",
    changedContract: "changedContract",
    decisions: "decisions",
    creditLimits: "creditLimits",
    isConditional: "isConditional",
    billingPeriod: "billingPeriod",
    retroUnconditionalDiscounts: "retroUnconditionalDiscounts",
    retroConditionalDiscounts: "retroConditionalDiscounts",
    threshold: "threshold",
    thresholds: "thresholds",
    sumOfDiscountUnconditional: "sumOfDiscountUnconditional",
    refundationInvoiceNumber: "refundationInvoiceNumber",
    nationalCourtRegister: "nationalCourtRegister",
    consentToCompensation: "consentToCompensation",
    value: "value",
    sgType: "sgType",
    text: "text",
    addAllProducts: "addAllProducts",
    marketingBudget: "marketingBudget",
    addMarketingBudget: "addMarketingBudget",
    typeOfDiscount: "typeOfDiscount",
    description: "description",
    isProducer: "isProducer",
    isSupplier: "isSupplier",
    isMiddleman: "isMiddleman",
    contractStatus: "contractStatus",
    TemporarySave: "TemporarySave",
    WaitingForVerification: "WaitingForVerification",
    Created: "Created",
    Generated: "Generated",
    Submitted: "Submitted",
    Accepted: "Accepted",
    Rejected: "Rejected",
    Draft: "Draft",
    DraftRejected: "DraftRejected",
    ChangeDraft: "ChangeDraft",
    ChangeDraftRejected: "ChangeDraftRejected",
    date: "date",
    turnover: "turnover",
    vendorTurnover: "vendorTurnover",
    turnovers: "turnovers",
    isAcceptedByVendor: "isAcceptedByVendor",
    isNotAcceptedByVendor: "isNotAcceptedByVendor",
    emailContent: "emailContent",
    month: "month",
    contractForm: "contractForm",
    contractFiles: "contractFiles",
    invoiceNumber: "invoiceNumber",
    invoiceDate: "invoiceDate",
    paymentDate: "paymentDate",
    deliveryDate: "deliveryDate",
    netValue: "netValue",
    grossValue: "grossValue",
    invoices: "invoices",
    OrdersList: "OrdersList",
    Vendors: "Vendors",
    Assistant: "Assistant",
    InvoiceType: "InvoiceType",
    assignedUsers: "assignedUsers",
    updateUsers: "updateUsers",
    vendorPurchaseOrder: "vendorPurchaseOrder",
    Percent: "Percent",
    Money: "Money",
    retroUnconditionalDiscountCountingMethod: "retroUnconditionalDiscountCountingMethod",
    Compensation: "Compensation",
    Transfer: "Transfer",
    invoiceSettlingType: "invoiceSettlingType",
    protocolRealisationFiles: "protocolRealisationFiles",
    creationDate: "creationDate",
    fileName: "fileName",
    documentNumber: "documentNumber",
    documentDate: "documentDate",
    documentSigningDate: "documentSigningDate",
    documentType: "documentType",
    documentCategory: "documentCategory",
    company: "company",
    files: "files",
    atLeastOneFileRequired: "atLeastOneFileRequired",
    termsOfBusinessPertainAllProducts: "termsOfBusinessPertainAllProducts",
    newContractFiles: "newContractFiles",
    filePreview: "filePreview",
    contractEmails: "contractEmails",
    warehouse: "warehouse",
    supplier: "supplier",
    vat: "vat",
    products: "products",
    exportPriceLists: "exportPriceLists",
    exportCurrentPurchasePrice: "exportCurrentPurchasePrice",
    importPromotionFileEmptyWarehouse: "importPromotionFileEmptyWarehouse",
    showOnlyAvailable: "showOnlyAvailable",
    oldSellPrice: "oldSellPrice",
    newSellPrice: "newSellPrice",
    settlementPeriod: "settlementPeriod",
    invoiceRecipient: "invoiceRecipient",
    subsidiaryGainType: "subsidiaryGainType",
    isPurchaseOrderRequired: "isPurchaseOrderRequired",
    purchaseOrderNumber: "purchaseOrderNumber",
    comment: "comment",
    subsidiaryGainServices: "subsidiaryGainServices",
    costReason: "costReason",
    costReasons: "costReasons",
    rejectedSubsidiaryGainMessage: "rejectedSubsidiaryGainMessage",
    invoiceErrorSubsidiaryGainMessage: "invoiceErrorSubsidiaryGainMessage",
    Current: "Current",
    Monthly: "Monthly",
    Quarterly: "Quarterly",
    Yearly: "Yearly",
    invoicedAt: "invoicedAt",
    serviceDocumentNumber: "serviceDocumentNumber",
    invoiceDocumentNumber: "invoiceDocumentNumber",
    responsibleUser: "responsibleUser",
    changeResponsibleUser: "changeResponsibleUser",
    replacements: "replacements",
    replacer: "replacer",
    quater: "quater",
    year: "year",
    importRefundationFile: "importRefundationFile",
    downloadRefundationFileModel: "downloadRefundationFileModel",
    refundationNumber: "refundationNumber",
    IsCorrected: "IsCorrected",
    changeStatus: "changeStatus",
    pwuFiles: "pwuFiles",
    changeReason: "changeReason",
    subsidiaryGainReport: "subsidiaryGainReport",
    downloadSubsidiaryGainReport: "downloadSubsidiaryGainReport",
    user: "user",
    vendorField: "vendorField",
    conditionalReopensForPeriod: "conditionalReopensForPeriod",
    InvoiceSummaryTotal: "InvoiceSummaryTotal",
    TransactionSummaryTotal: "TransactionSummaryTotal",
    VendorSummaryTotal: "VendorSummaryTotal",
    monthsWithTurnovers: "monthsWithTurnovers",
    turnoverForm: "turnoverForm",
    dateOfCorrection: "dateOfCorrection",
    correctedBy: "correctedBy",
    oldVendorSummary: "oldVendorSummary",
    oldTransactionSummary: "oldTransactionSummary",
    oldInvoiceSummary: "oldInvoiceSummary",
    newVendorSummary: "newVendorSummary",
    newTransactionSummary: "newTransactionSummary",
    newInvoiceSummary: "newInvoiceSummary",
    vendorSummary: "vendorSummary",
    transactionSummary: "transactionSummary",
    invoiceSummary: "invoiceSummary",
    noTurnover: "noTurnover",
    vendorSummaryDifference: "vendorSummaryDifference",
    invoiceSummaryTotal: "invoiceSummaryTotal",
    transactionSummaryTotal: "transactionSummaryTotal",
    vendorSummaryTotal: "vendorSummaryTotal",
    turnoverSummary: "turnoverSummary",
    confirmationFiles: "confirmationFiles",
    importingRefundation: "importingRefundation",
    refundationSuccesfullyImported: "refundationSuccesfullyImported",
    refundationImportedWithErrors: "refundationImportedWithErrors",
    correction: "correction",
    DecisionNotRequired: "DecisionNotRequired",
    WaitingForDecision: "WaitingForDecision",
    ServiceAccepted: "ServiceAccepted",
    ChangeAccepted: "ChangeAccepted",
    ServiceRejected: "ServiceRejected",
    ChangeRejected: "ChangeRejected",
    ServiceRemoved: "ServiceRemoved",
    updatedAt: "updatedAt",
    removalProposal: "removalProposal",
    rejectRemoval: "rejectRemoval",
    acceptRemoval: "acceptRemoval",
    refundationValues: "refundationValues",
    consentToAutomaticCompensation: "consentToAutomaticCompensation",
    frontDiscounts: "frontDiscounts",
    paymentTermsDateFrom: "paymentTermsDateFrom",
    calculationBase: "calculationBase",
    attention: "attention",
    changesWillRequireDecision: "changesWillRequireDecision",
    NoOp: "NoOp",
    RequiresDecision: "RequiresDecision",
    creditLimitSecurity: "creditLimitSecurity",
    creditLimitHasSecurity: "creditLimitHasSecurity",
    creditLimitSecurityValue: "creditLimitSecurityValue",
    guarantor: "guarantor",
    creditLimitPending: "creditLimitPending",
    creditLimitSuccess: "creditLimitSuccess",
    creditLimitError: "creditLimitError",
    creditLimitRetryMessage: "creditLimitRetryMessage",
    documents: "documents",
    accountingDate: "accountingDate",
    recipientId: "recipientId",
    serviceCode: "serviceCode",
    currentGrossPrice: "currentGrossPrice",
    getInvoice: "getInvoice",
    getInvoiceCorrection: "getInvoiceCorrection",
    invoice: "invoice",
    invoiceCorrection: "invoiceCorrection",
    addInvoiceCorrection: "addInvoiceCorrection",
    correctionError: "correctionError",
    correctionPending: "correctionPending",
    sgInvoiceCorrection: "sgInvoiceCorrection",
    correctionOk: "correctionOk",
    documentStatus: "documentStatus",
    retry: "retry",
    netPrice: "netPrice",
    reason: "reason",
    dateOfStartContract: "dateOfStartContract",
    dateOfEndContract: "dateOfEndContract",
    Corrected: "Corrected",
    Annexed: "Annexed",
    Mailed: "Mailed",
    Closed: "Closed",
    ContractRejectedMessage: "ContractRejectedMessage",
    productDiscounts: "productDiscounts",
    discountCountingMethod: "discountCountingMethod",
    discountSettlementMethod: "discountSettlementMethod",
    settlementBase: "settlementBase",
    id: "id",
    parentId: "parentId",
    level: "level",
    isActive: "isActive",
    analyticGroup: "analyticGroup",
    inheredCategoryManagers: "inheredCategoryManagers",
    categoryManagers: "categoryManagers",
    analyticGroupsList: "analyticGroupsList",
    name: "name",
    shortName: "shortName",
    businessRegistryNumber: "businessRegistryNumber",
    vatin: "vatin",
    postcode: "postcode",
    city: "city",
    street: "street",
    buildingNumber: "buildingNumber",
    apartmentNumber: "apartmentNumber",
    country: "country",
    baseContact: "baseContact",
    isJustInTime: "isJustInTime",
    pricesInPurchaseOrder: "pricesInPurchaseOrder",
    pricesContainsTax: "pricesContainsTax",
    canReturn100: "canReturn100",
    orderInPurchaseUnit: "orderInPurchaseUnit",
    payerAccount: "payerAccount",
    receiverAccount: "receiverAccount",
    timeOfRealisation: "timeOfRealisation",
    minimumValueOfLogistics: "minimumValueOfLogistics",
    recordType: "recordType",
    vendorGroup: "vendorGroup",
    currency: "currency",
    taxGroup: "taxGroup",
    paymentTerms: "paymentTerms",
    paymentMethod: "paymentMethod",
    accountNumberCountry: "accountNumberCountry",
    vatinCountry: "vatinCountry",
    deliveryTerms: "deliveryTerms",
    deliveryMethod: "deliveryMethod",
    Protocol: "Protocol",
    MissingPurchaseOrder: "MissingPurchaseOrder",
    Accounting: "Accounting",
    MarketingInvoices: "MarketingInvoices",
    EInvoice: "EInvoice",
    swiftCode: "swiftCode",
    accountNumber: "accountNumber",
    language: "language",
    industryName: "industryName",
    isTaxDateRequired: "isTaxDateRequired",
    download: "download",
    emails: "emails",
    bankAccounts: "bankAccounts",
    billingAddress: "billingAddress",
    vendorOperation: "vendorOperation",
    vendorOperationNew: "vendorOperationNew",
    vendorOperations: "vendorOperations",
    status: "status",
    AuditRequested: "AuditRequested",
    Completed: "Completed",
    add: "add",
    isArchiveOnly: "isArchiveOnly",
    merchant: "merchant",
    refundationSettlement: "refundationSettlement",
    archiveDocument: "archiveDocument",
    documentDefinition: "documentDefinition",
    noSignatureReason: "noSignatureReason",
    noFile: "noFile",
    contractChange: "contractChange",
    contractAnnex: "contractAnnex",
    contractMail: "contractMail",
    noAccessToDocumentTypes: "noAccessToDocumentTypes",
    noAccessToDocumentCategories: "noAccessToDocumentCategories",
    document: "document",
    subsidiaryGainWithoutInvoice: "subsidiaryGainWithoutInvoice",
    creditAmount: "creditAmount",
    paymentTerm: "paymentTerm",
    signedDocument: "signedDocument",
    acceptForm: "acceptForm",
    rejectForm: "rejectForm",
    uploadSignedForm: "uploadSignedForm",
    uploadSignedByCustmerForm: "uploadSignedByCustmerForm",
    downloadForm: "downloadForm",
    dwonloadSignedForm: "dwonloadSignedForm",
    none: "none",
    saleDate: "saleDate",
    representativesAfterComma: "representativesAfterComma",
    containers: "containers",
    containerType: "containerType",
    containerIdOrLink: "containerIdOrLink",
    containerName: "containerName",
    containerCategoryName: "containerCategoryName",
    containerSectionName: "containerSectionName",
    containerCampaignName: "containerCampaignName",
    CMS: "CMS",
    Promotion: "Promotion",
    Discount: "Discount",
    containerNotFound: "containerNotFound",
    confirmations: "confirmations",
    otherConfirmations: "otherConfirmations",
    sgRejectReasonPlaceholder: "sgRejectReasonPlaceholder",
    rejectSG: "rejectSG",
    WaitingForVendorDecision: "WaitingForVendorDecision",
    InProgress: "InProgress",
    Removed: "Removed",
    InvoiceRequested: "InvoiceRequested",
    Invoiced: "Invoiced",
    InvoiceFailed: "InvoiceFailed",
    AcceptedByVendor: "AcceptedByVendor",
    RejectedByVendor: "RejectedByVendor",
    ConfirmationAcceptedByVendor: "ConfirmationAcceptedByVendor",
    ConfirmationRejectedByVendor: "ConfirmationRejectedByVendor",
    requestVendorDecision: "requestVendorDecision",
    vendorUser: "vendorUser",
    WaitingForVendorConfirmation: "WaitingForVendorConfirmation",
    ConfirmedByVendor: "ConfirmedByVendor",
    requestVendorConfirmation: "requestVendorConfirmation",
    requestInvoiceRetry: "requestInvoiceRetry",
    arrangements: "arrangements",
    settlementType: "settlementType",
    correctionRequests: "correctionRequests",
    correctionRequestNumber: "correctionRequestNumber",
    Refundation: "Refundation",
    RetroUnconditional: "RetroUnconditional",
    RetroConditional: "RetroConditional",
    CorrectionReceived: "CorrectionReceived",
    CorrectionValue: "CorrectionValue",
    TurnoverValue: "TurnoverValue",
    correctionRequestReport: "correctionRequestReport",
    downloadCorrectionRequestReport: "downloadCorrectionRequestReport",
    downloadCorrectionRequestReportFile: "downloadCorrectionRequestReportFile",
    acceptAllCorrectionRequest: "acceptAllCorrectionRequest",
    refundation: "refundation",
    retro: "retro",
    correctionValue: "correctionValue",
    confirmCorrectionRequestModalText: "confirmCorrectionRequestModalText",
    confirmCorrectionRequestModalTitle: "confirmCorrectionRequestModalTitle",
    correctionRequestData: "correctionRequestData",
    Editing: "Editing",
    addContainer: "addContainer",
    isDeliveringBio: "isDeliveringBio",
    expiredBioCertificateTitle: "expiredBioCertificateTitle",
    expiredBioCertificateText: "expiredBioCertificateText",
    certificates: "certificates",
    expirationDate: "expirationDate",
    certificateProvider: "certificateProvider",
    isForChemistry: "isForChemistry",
    isForGroceries: "isForGroceries",
    certificateForm: "certificateForm",
    certificateNumber: "certificateNumber",
    certificationDate: "certificationDate",
    uploadDate: "uploadDate",
    certificatesForProduct: "certificatesForProduct",
    appliesToAllProducts: "appliesToAllProducts",
    isProducerCertificate: "isProducerCertificate",
    Contract: "Contract",
    goToReservationKit: "goToReservationKit",
    costReasonFromReservation: "costReasonFromReservation",
    budgetDatesError: "budgetDatesError",
    catmanBudgetOverLimit: "catmanBudgetOverLimit",
    catmansBudgetOverLimit: "catmansBudgetOverLimit",
    budgetFormOverLimit: "budgetFormOverLimit",
    rejectedMessageWithComment: "rejectedMessageWithComment",
    serviceType: "serviceType",
    isRestrictedToPromotion: "isRestrictedToPromotion",
    isRestrictedToClients: "isRestrictedToClients",
    clientsIds: "clientsIds",
    maxQuantity: "maxQuantity",
    RequiresCorrectionRequestAction: "RequiresCorrectionRequestAction",
    correctionRequestZeroValue: "correctionRequestZeroValue",
    acceptZeroCorrectionRequest: "acceptZeroCorrectionRequest",
    goToCorrectionRequest: "goToCorrectionRequest",
} as const;

export default vendor;
