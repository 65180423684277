import { CustomerContactDocument } from "Commerce-Support";
import { useParams } from "utils/hooks";
import React from "react";
import { api } from "api";
import { useColumns } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";

const SupportContactList: React.FC = () => {
    const { customerId = "" } = useParams();
    const { data: employees = [], status: employeesStatus } = api.operations.autocomplete.useFindEmployees();
    const columns = useColumns<CustomerContactDocument>(() => [
        {
            property: "contactType",
            label: "contactType",
        },
        {
            property: "createdAt",
            label: "createdAt",
            as: "dateTime",
        },
        {
            property: "subjects",
            label: "subjects",
            as: (_, item) => item.subjects.map((o) => <p style={{ marginTop: "0px", marginBottom: "0px" }}>{o.workCodes.join("⭢")}</p>),
        },
        {
            property: "comment",
            label: "comment",
        },
        {
            property: "createdBy",
            label: "createdBy",
            as: (value: unknown) =>
                !value || employeesStatus != "success" ? "" : employees.find((e) => e.id == (value as string))?.name || "Nieznany użytkownik",
        },
    ]);

    return (
        <QueryTable
            queryHook={(params) => api.commerce.contact.useGetCustomerContacts(customerId, params)}
            columns={columns}
            hideToggle
            hideReload
            canAddItem={false}
            formPath={Paths.Customer.SupportContact}
        />
    );
};

export default SupportContactList;
