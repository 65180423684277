const content = {
    alerts: "alerts",
    clearAllAlerts: "clearAllAlerts",
    alertType: "alertType",
    objectId: "objectId",
    timestamp: "timestamp",
    additionalInfo: "additionalInfo",
    actionTaken: "actionTaken",
    updateBrands: "updateBrands",
    subbrand: "subbrand",
    subbrands: "subbrands",
    update: "update",
    title: "title",
    metaDescription: "metaDescription",
    logoUrl: "logoUrl",
    logoAltText: "logoAltText",
    imageAltText: "imageAltText",
    producer: "producer",
    isSubbrand: "isSubbrand",
    isActive: "isActive",
    level: "level",
    polishName: "polishName",
    englishName: "englishName",
    addAllFromPage: "addAllFromPage",
    productsHaveBeenAssignedToTheCategory: "productsHaveBeenAssignedToTheCategory",
    productIsAlreadyAssignedToTheCategory: "productIsAlreadyAssignedToTheCategory",
    productHasBeenAssignedToTheCategory: "productHasBeenAssignedToTheCategory",
    category: "category",
    name: "name",
    eans: "eans",
    unitOfMeasure: "unitOfMeasure",
    grammage: "grammage",
    productId: "productId",
    brand: "brand",
    onlyOneCodePerTree: "onlyOneCodePerTree",
    categoryTree: "categoryTree",
    code: "code",
    codesInTrees: "codesInTrees",
    createCategory: "createCategory",
    addCategory: "addCategory",
    addedCategoryIsAlreadyInTheTree: "addedCategoryIsAlreadyInTheTree",
    categoryHasBeenAddedToTheTree: "categoryHasBeenAddedToTheTree",
    treeStructure: "treeStructure",
    productIsAlreadyAddedToCategory: "productIsAlreadyAddedToCategory",
    productHasBeenAddedToCategory: "productHasBeenAddedToCategory",
    categoryTrees: "categoryTrees",
    create: "create",
    createBrand: "createBrand",
    imageUrl: "imageUrl",
    createCategoryTree: "createCategoryTree",
    rootCategoryId: "rootCategoryId",
    productHasBeenInited: "productHasBeenInited",
    alert: "alert",
    save: "save",
    fill: "fill",
    reject: "reject",
    accept: "accept",
    state: "state",
    analyticGroup: "analyticGroup",
    storageType: "storageType",
    alcoholByVolume: "alcoholByVolume",
    defaultSupplier: "defaultSupplier",
    vatStake: "vatStake",
    storageTerms: "storageTerms",
    dry: "dry",
    degrees1013: "degrees1013",
    degrees36: "degrees36",
    frozen: "frozen",
    fullValidityPeriod: "fullValidityPeriod",
    remainingSalePeriod: "remainingSalePeriod",
    acceptableOverWeight: "acceptableOverWeight",
    plannedSale: "plannedSale",
    minimalStockDays: "minimalStockDays",
    maximumStockDays: "maximumStockDays",
    contractedReceivePeriod: "contractedReceivePeriod",
    categoryManager: "categoryManager",
    piecesInCarton: "piecesInCarton",
    validityPeriodOnReceive: "validityPeriodOnReceive",
    marginFactor: "marginFactor",
    minPieces: "minPieces",
    maxPieces: "maxPieces",
    rotationCode: "rotationCode",
    promotionStart: "promotionStart",
    height: "height",
    width: "width",
    depth: "depth",
    weightNet: "weightNet",
    weightGross: "weightGross",
    planInShoppingUnit: "planInShoppingUnit",
    isValidityPeriodIgnored: "isValidityPeriodIgnored",
    variant: "variant",
    initProduct: "initProduct",
    variants: "variants",
    fileName: "fileName",
    isRejected: "isRejected",
    reviewerType: "reviewerType",
    review: "review",
    Accepted: "Accepted",
    Rejected: "Rejected",
    Auditor: "Auditor",
    Trade: "Trade",
    Inited: "Inited",
    Filled: "Filled",
    Audited: "Audited",
    Sent: "Sent",
    ToCorrect: "ToCorrect",
    ReviewedByTrade: "ReviewedByTrade",
    InitedByVendor: "InitedByVendor",
    RejectedByAuditor: "RejectedByAuditor",
    RejectedByTrade: "RejectedByTrade",
    Created: "Created",
    UpdateInited: "UpdateInited",
    Updated: "Updated",
    type: "type",
    sellUnit: "sellUnit",
    purchaseUnit: "purchaseUnit",
    friscoIdUnitOfMeasure: "friscoIdUnitOfMeasure",
    productDimensionGroup: "productDimensionGroup",
    positionModelGroup: "positionModelGroup",
    positionGroup: "positionGroup",
    batchNumberGroup: "batchNumberGroup",
    storageDimensionGroup: "storageDimensionGroup",
    trackingDimensionGroup: "trackingDimensionGroup",
    availableOnPlatform: "availableOnPlatform",
    justInTime: "justInTime",
    largeSize: "largeSize",
    cut: "cut",
    utilization: "utilization",
    returnableUnit: "returnableUnit",
    vatStakePurchase: "vatStakePurchase",
    vatStakeSell: "vatStakeSell",
    filesRequired: "filesRequired",
    nutritionsRequired: "nutritionsRequired",
    compositionRequired: "compositionRequired",
    currentFiles: "currentFiles",
    description: "description",
    auditFields: "auditFields",
    vatStakeSellRequired: "vatStakeSellRequired",
    imageRequired: "imageRequired",
    unitMultipack: "unitMultipack",
    multipackLayer: "multipackLayer",
    layerPalette: "layerPalette",
    multipackPalette: "multipackPalette",
    unitPalette: "unitPalette",
    multipackInfo: "multipackInfo",
    singlePackInfo: "singlePackInfo",
    productAttributes: "productAttributes",
    missingRequired: "missingRequired",
    missingRequiredTrade: "missingRequiredTrade",
    productHasBeenSent: "productHasBeenSent",
    cnCode: "cnCode",
    cnDescription: "cnDescription",
    analyticGroupSelected: "analyticGroupSelected",
    normal: "normal",
    cooler: "cooler",
    freezer: "freezer",
    comment: "comment",
    storageUnit: "storageUnit",
    warehouses: "warehouses",
    supplier: "supplier",
    useDefaultSupplier: "useDefaultSupplier",
    warehouse: "warehouse",
    isSold: "isSold",
    mpp: "mpp",
    purchasePrice: "purchasePrice",
    productVariantId: "productVariantId",
    gtu: "gtu",
    store: "store",
    stores: "stores",
    superiorStore: "superiorStore",
    isSampling: "isSampling",
    isManufacturedProduct: "isManufacturedProduct",
    manufacturedProductId: "manufacturedProductId",
    manufacturedProductName: "manufacturedProductName",
    missingRequiredCatman: "missingRequiredCatman",
    missingFrontPhoto: "missingFrontPhoto",
    toAddNewFilesForTheProductDragThemOrClickHere: "toAddNewFilesForTheProductDragThemOrClickHere",
    init: "init",
    filledByTrade: "filledByTrade",
    vendorId: "vendorId",
    vendorName: "vendorName",
    initProducts: "initProducts",
    creationDate: "creationDate",
    statusChangedAt: "statusChangedAt",
    dictionaryValues: "dictionaryValues",
    alternativeTrees: "alternativeTrees",
    mainTree: "mainTree",
    noCategoryTrees: "noCategoryTrees",
    eanCodes: "eanCodes",
    attributesFrom: "attributesFrom",
    noIntegration: "noIntegration",
    brandbankStatus: "brandbankStatus",
    error: "error",
    bioplanetStatus: "bioplanetStatus",
    setIntegrationImageIfPossible: "setIntegrationImageIfPossible",
    None: "None",
    integration: "integration",
    setAsMain: "setAsMain",
    deleteImage: "deleteImage",
    toAddNewPhotosForTheProductDragThemOrClickHere: "toAddNewPhotosForTheProductDragThemOrClickHere",
    toAddNewImagesDragThemOrClickHere: "toAddNewImagesDragThemOrClickHere",
    acceptedFiles: "acceptedFiles",
    filter: "filter",
    ean: "ean",
    Attributes: "Attributes",
    Variants: "Variants",
    Images: "Images",
    Reviews: "Reviews",
    Files: "Files",
    Warehouses: "Warehouses",
    downloadTemplate: "downloadTemplate",
    Categories: "Categories",
    ok: "ok",
    Brandbank: "Brandbank",
    Bioplanet: "Bioplanet",
    vendorVatStatements: "vendorVatStatements",
    categoryId: "categoryId",
    filledBy: "filledBy",
    replenishmentStore: "replenishmentStore",
    changedAt: "changedAt",
    changedBy: "changedBy",
    oldValue: "oldValue",
    newValue: "newValue",
    newFiles: "newFiles",
    saveImages: "saveImages",
    productInnerData: "productInnerData",
    productSellAndBuyData: "productSellAndBuyData",
    productBuyData: "productBuyData",
    productWarehouseData: "productWarehouseData",
    productAttributesFlags: "productAttributesFlags",
    isMainCategory: "isMainCategory",
    section: "section",
    subAttributes: "subAttributes",
    selectValues: "selectValues",
    productAttribute: "productAttribute",
    includeRawData: "includeRawData",
    mainCategory: "mainCategory",
    additionalCategories: "additionalCategories",
    additionalCategory: "additionalCategory",
    BioCertificate: "BioCertificate",
    VeganCertificate: "VeganCertificate",
    Other: "Other",
} as const;

export default content;
