import qs from "query-string";
import { useDebouncedCallback } from "use-debounce";
import React from "react";
import { useLocation } from "react-router-dom";
import { Add as AddIcon, GetApp as ExportIcon, Cached as RefreshIcon } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { UseMutationResult } from "@tanstack/react-query";
import { Query } from "api-types";
import { common } from "translations";
import { useMessages, usePush, useTranslation } from "utils-ts/hooks";
import { FacetPagedCriteria, FacetPagedResult, PagedCriteria, PagedResult, QueryTableParams } from "Shared";
import { Paths } from "routing-ts/ManagerPaths";
import { Button, ToggleButtonGroup } from "components-ts/controls";
import { AcceptRejectDialog } from "components-ts/dialogs";
import Facets from "components-ts/facets/Facets";
import { ActionColumn, Column, Facet, Table, TableHandlers, TableItem, ValueColumn } from "components-ts/tables/table";
import { Spacing } from "components-ts/view";

type DataFilter<TParams extends FacetPagedCriteria | QueryTableParams | PagedCriteria> = Partial<
    Omit<TParams, "pageSize" | "pageIndex" | "includeFacets" | "isActive">
>;

type QueryTableProps<TModel extends TableItem, TParams extends FacetPagedCriteria | QueryTableParams | PagedCriteria> = {
    queryHook: (initialParams: TParams) => Query.UseQueryResult<FacetPagedResult<TModel> | PagedResult<TModel>, TParams, {}>;
    columns: Column<TModel>[];
    formPath?: string;
    /** use when newForm is different then formPath ex. InitProductDetails */
    newFormPath?: string;
    canAddItem?: boolean;
    hideToggle?: boolean;
    hideReload?: boolean;
    saveFilters?: boolean;
    handleDoubleClick?: boolean;
    useBodyParams?: boolean;
    customFilter?: (initialParams: DataFilter<TParams>, onChangeFilter: (property: string, value: unknown) => void) => React.ReactNode;
    columnFilterToParams?: (column: keyof TModel, value: unknown, params: TParams) => DataFilter<TParams>;
    defaultParams?: DataFilter<TParams> & { isActive: boolean };
    getItemId?: (item: TModel) => string;
} & (
    | {
          removeHook: (id?: string | undefined) => UseMutationResult<void, Response, {}, unknown>;
          getItemRemoveName?: (item: TModel) => string;
      }
    | {
          removeHook?: never;
          getItemRemoveName?: never;
      }
) &
    (
        | {
              facets?: never;
              facetPosition?: never;
              facetFilterToParams?: never;
          }
        | {
              facets: Facet<TParams>[];
              facetPosition?: "top" | "left";
              facetFilterToParams?: (column: keyof TParams, value: unknown, params: TParams) => DataFilter<TParams>;
          }
    ) &
    (
        | {
              exportable: boolean;
              onExport: (itemIds: string[], isSelectedAll: boolean, params: TParams) => void;
              isExporting: boolean;
              canItemBeExported?: (item: TModel) => boolean;
          }
        | {
              exportable?: never;
              onExport?: never;
              isExporting?: never;
              canItemBeExported?: never;
          }
    );

type SessionFilter<TParams extends FacetPagedCriteria | QueryTableParams | PagedCriteria> = {
    page: string;
    filterState: TParams;
};

const QueryTable = <TModel extends TableItem, TParams extends FacetPagedCriteria | QueryTableParams | PagedCriteria>({
    queryHook,
    columns,
    formPath,
    newFormPath,
    canAddItem = true,
    hideToggle = false,
    hideReload = false,
    saveFilters = true,
    handleDoubleClick = true,
    useBodyParams = false,
    customFilter = undefined,
    columnFilterToParams = undefined,
    facets = undefined,
    facetPosition = "left",
    facetFilterToParams = undefined,
    defaultParams = undefined,
    removeHook = undefined,
    getItemId = undefined,
    getItemRemoveName = undefined,
    exportable,
    onExport,
    isExporting,
    canItemBeExported,
}: QueryTableProps<TModel, TParams>) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { push, replaceQuery } = usePush();
    const { showSuccessMessage, showErrorMessage, showInfoMessage } = useMessages();
    const [itemToRemove, setItemToRemove] = React.useState<TModel | undefined>(undefined);
    const [selected, setSelected] = React.useState<{ isSelectAll: boolean; selectedRows: string[] }>({ isSelectAll: false, selectedRows: [] });

    let initialParams: TParams | undefined = undefined;
    const removeMutation = removeHook ? removeHook(itemToRemove && getItemId ? getItemId(itemToRemove) : itemToRemove?.id?.toString()) : undefined;

    const sessionFilterData = sessionStorage.getItem("dataListFilter");
    if (sessionFilterData) {
        const sessionFilter = JSON.parse(sessionFilterData) as SessionFilter<TParams>;
        if (sessionFilter.page === location.pathname) {
            initialParams = sessionFilter.filterState;
        }
    }
    const parsedQuery = qs.parse(location.search) as unknown as TParams;
    const defaultTableParams: TParams = {
        includeFacets: (facets || []).length > 0 ? true : undefined,
        isActive: !hideToggle ? true : undefined,
        ...defaultParams,
        pageIndex: 1,
        pageSize: localStorage.getItem("tablePagination") !== null ? JSON.parse(localStorage.getItem("tablePagination") as string) : 10,
    } as unknown as TParams;

    if (!initialParams) {
        if (Object.keys(parsedQuery).length > 0) {
            initialParams = parsedQuery;
        } else {
            initialParams = defaultTableParams as TParams;
        }
    }

    if (columnFilterToParams || facetFilterToParams) {
        (Object.keys(initialParams) as (keyof typeof initialParams)[]).forEach((key) => {
            if (initialParams && initialParams[key]) {
                const columnFilter = columnFilterToParams ? columnFilterToParams(key.toString(), initialParams[key], initialParams) : {};
                const facetFilter = facetFilterToParams ? facetFilterToParams(key, initialParams[key], initialParams) : {};
                initialParams = {
                    ...initialParams,
                    ...columnFilter,
                    ...facetFilter,
                };
            }
        });
    }

    const { setQueryParams, setBodyParams, bodyParams, queryParams, isRefetching, ...query } = queryHook(initialParams);

    const updateSessionParams = (property: string, value: unknown) => {
        let sessionFilter: SessionFilter<TParams> | undefined = undefined;
        const sessionFilterData = sessionStorage.getItem("dataListFilter");
        if (sessionFilterData) {
            const lastSessionFilter = JSON.parse(sessionFilterData) as SessionFilter<TParams>;
            if (lastSessionFilter.page === location.pathname) {
                sessionFilter = lastSessionFilter;
            }
        }

        if (sessionFilter) {
            sessionFilter.filterState = {
                ...sessionFilter.filterState,
                [property]: value,
            };
        } else {
            sessionFilter = {
                page: location.pathname,
                filterState: {
                    [property]: value,
                    ...defaultTableParams,
                } as TParams,
            };
        }

        sessionStorage.setItem("dataListFilter", JSON.stringify(sessionFilter));
    };

    const clearParams = () => {
        setSelected({ isSelectAll: selected.isSelectAll, selectedRows: [] });
        const params = useBodyParams ? (bodyParams as TParams) : queryParams;
        const keys = Object.keys(params).filter((k) => k !== "pageIndex" && ((facets || []).length > 0 ? k !== "includeFacets" : true));
        let temp = {};
        keys.forEach((key) => {
            temp = { ...temp, [key]: undefined };
            if (saveFilters) {
                updateSessionParams(key, undefined);
            }
        });

        temp = {
            ...temp,
            pageIndex: 1,
            includeFacets: (facets || []).length > 0 ? true : undefined,
        };

        if (useBodyParams) {
            setBodyParams(temp);
        } else {
            setQueryParams(temp as TParams);
        }
    };

    const updateParams = (property: keyof TModel | keyof TParams, value: unknown) => {
        setSelected({ isSelectAll: selected.isSelectAll, selectedRows: [] });
        const newValue = value === "" ? undefined : value;
        const params = useBodyParams ? (bodyParams as TParams) : queryParams;
        const columnFilter =
            columnFilterToParams && (property as keyof TModel) !== undefined ? columnFilterToParams(property as keyof TModel, value, params) : {};
        const facetFilter =
            facetFilterToParams && (property as keyof TParams) !== undefined ? facetFilterToParams(property as keyof TParams, value, params) : {};
        if (useBodyParams) {
            setBodyParams({
                ...params,
                pageIndex: 1,
                [property.toString()]: newValue,
                ...columnFilter,
                ...facetFilter,
            });
        } else {
            setQueryParams({
                ...params,
                pageIndex: 1,
                [property.toString()]: newValue,
                ...columnFilter,
                ...facetFilter,
            } as TParams);
        }

        if (saveFilters) {
            updateSessionParams(property.toString(), value);
            if (columnFilter) {
                (Object.keys(columnFilter) as (keyof typeof columnFilter)[]).forEach((k) => {
                    updateSessionParams(k, columnFilter[k]);
                });
            }

            if (facetFilter) {
                (Object.keys(facetFilter) as (keyof typeof facetFilter)[]).forEach((k) => {
                    updateSessionParams(k, facetFilter[k]);
                });
            }
        }
    };

    const updateCustomParams = (property: string, value: unknown) => {
        const newValue = value === "" ? undefined : value;
        const params = useBodyParams ? (bodyParams as TParams) : queryParams;
        const columnFilter =
            columnFilterToParams && (property as keyof TModel) !== undefined ? columnFilterToParams(property as keyof TModel, value, params) : {};
        const facetFilter =
            facetFilterToParams && (property as keyof TParams) !== undefined ? facetFilterToParams(property as keyof TParams, value, params) : {};
        if (useBodyParams) {
            setBodyParams({
                ...params,
                pageIndex: 1,
                [property]: newValue,
                ...columnFilter,
                ...facetFilter,
            });
        } else {
            setQueryParams({
                ...params,
                pageIndex: 1,
                [property]: newValue,
                ...columnFilter,
                ...facetFilter,
            } as TParams);
        }

        if (saveFilters) {
            updateSessionParams(property, value);
        }
    };

    const updateFilter = useDebouncedCallback(updateParams, 500);

    const tableHandlers: TableHandlers<TModel> = {
        onFilterChange: columns.some((c) => (c as ValueColumn<TModel>).filtrable) ? updateFilter : undefined,
        onFilterClear: columns.some((c) => (c as ValueColumn<TModel>).filtrable) ? clearParams : undefined,
        onSelectAllChange: exportable
            ? (newValue) => {
                  setSelected({ isSelectAll: newValue ?? false, selectedRows: selected.selectedRows });
              }
            : undefined,
    };

    if (handleDoubleClick && formPath) {
        tableHandlers.onRowDoubleClick = (item: TModel) => {
            if (formPath) {
                push(
                    Paths.GeneratePath(formPath, {
                        id: getItemId ? getItemId(item) : item.id,
                    })
                );
            }
        };
    }

    const removeItem = async () => {
        if (removeMutation) {
            await removeMutation.mutateAsync(
                {},
                {
                    onSuccess: () => {
                        showSuccessMessage("Pomyślnie usunięto");
                    },
                    onError: (error) => {
                        showErrorMessage("Błąd przy usuwaniu");
                        console.error(error);
                    },
                }
            );

            setItemToRemove(undefined);
            query.refetch();
        } else {
            throw new Error("Missing removeMutation");
        }
    };

    function hasObjectKey<T extends {}>(obj: T, key: PropertyKey): key is keyof T {
        return key in obj;
    }

    const isAction = <T extends TableItem>(column: ActionColumn<T> | ValueColumn<T>): column is ActionColumn<T> => {
        return (column as ActionColumn<T>).actionType !== undefined;
    };

    let cols = columns.some((c) => isAction(c) && c.actionType === "delete" && c.onClick === undefined)
        ? columns.map((c) =>
              isAction(c) && c.actionType === "delete" && c.onClick === undefined ? { ...c, onClick: (item: TModel) => setItemToRemove(item) } : c
          )
        : columns;

    if (exportable) {
        cols = [
            {
                actionType: "select",
                getItemId,
                onClick: (item) => {
                    const itemId = getItemId ? getItemId(item) : item.id?.toString();
                    if (!itemId) {
                        return;
                    }

                    const isSelected = selected.selectedRows.includes(itemId);
                    setSelected({
                        isSelectAll: selected.isSelectAll,
                        selectedRows: isSelected ? selected.selectedRows.filter((r) => r !== itemId) : [...selected.selectedRows, itemId],
                    });
                },
                isItemSelected: (item) => {
                    const itemId = getItemId ? getItemId(item) : item.id?.toString();
                    if (!itemId) {
                        return false;
                    } else {
                        return (
                            (canItemBeExported ? canItemBeExported(item) : true) && (selected.isSelectAll || selected.selectedRows.includes(itemId))
                        );
                    }
                },
                cellProps: { size: "small" },
                canBeSelected: (item) => (canItemBeExported ? canItemBeExported(item) : true),
            },
            ...cols,
        ];
    }

    const table = (
        <Table
            columns={cols}
            items={query.data?.items}
            pagination={{
                ...query.data,
                onPageChange: (pageIndex) => {
                    replaceQuery({
                        ...queryParams,
                        pageIndex: pageIndex,
                    });
                    updateParams("pageIndex", pageIndex);
                },
                onPageSizeChange: (pageSize) => {
                    localStorage.setItem("tablePagination", pageSize.toString());
                    replaceQuery({
                        ...queryParams,
                        pageIndex: 1,
                        pageSize: pageSize,
                    });
                    updateParams("pageIndex", 1);
                    updateParams("pageSize", pageSize);
                },
            }}
            loadable={{
                ...queryParams,
                ...query,
                isRefetching,
            }}
            handlers={tableHandlers}
            initialFilters={columns
                .filter((c) => (c as ValueColumn<TModel>) !== null && (c as ValueColumn<TModel>).property)
                .map((c) => (c as ValueColumn<TModel>).property.toString())
                .reduce((accumulator, current) => {
                    if (!current) {
                        return accumulator;
                    } else {
                        return {
                            ...accumulator,
                            [current.toString()]: initialParams && hasObjectKey(initialParams, current) ? initialParams[current] : undefined,
                        };
                    }
                }, {} as TModel)}
        />
    );

    const facet =
        facets && facets.length > 0 ? (
            <Facets
                facets={facets}
                initialParams={initialParams}
                updateQueryParams={updateCustomParams}
                searchFacets={query.status === "success" && "facets" in query.data ? query.data.facets : []}
                orientation={facetPosition === "left" ? "vertical" : "horizontal"}
            />
        ) : undefined;

    return (
        <>
            {(!hideToggle || canAddItem || !hideReload || customFilter || (facet && facetPosition === "top") || exportable) && (
                <Spacing spacing={1}>
                    <>
                        {customFilter && customFilter(initialParams, updateCustomParams)}

                        {(!hideToggle || canAddItem || !hideReload) && (
                            <Grid container>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Grid
                                        spacing={1}
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ display: "inline-flex" }}
                                    >
                                        {!hideToggle && (
                                            <Grid item>
                                                <ToggleButtonGroup
                                                    value={queryParams && "isActive" in queryParams ? String(queryParams?.isActive) : "undefined"}
                                                    items={[
                                                        {
                                                            name: t(common.active),
                                                            value: "true",
                                                        },
                                                        {
                                                            name: t(common.inActive),
                                                            value: "false",
                                                        },
                                                        {
                                                            name: t(common.all),
                                                            value: "undefined",
                                                        },
                                                    ]}
                                                    onChange={(value) => {
                                                        let isActive;
                                                        if (value === "true") {
                                                            isActive = true;
                                                        }
                                                        if (value === "false") {
                                                            isActive = false;
                                                        }

                                                        updateFilter("isActive", isActive);
                                                    }}
                                                    hideSelectedIcon
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Grid
                                        spacing={1}
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        style={{ display: "inline-flex" }}
                                    >
                                        {exportable && (
                                            <Grid item>
                                                <Button
                                                    startIcon={<ExportIcon />}
                                                    color="primary"
                                                    label="export"
                                                    isLoading={isExporting}
                                                    disabled={!selected.isSelectAll && selected.selectedRows.length === 0}
                                                    onClick={() => {
                                                        onExport(
                                                            selected.selectedRows,
                                                            selected.isSelectAll,
                                                            useBodyParams ? (bodyParams as TParams) : queryParams
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        )}

                                        {!hideReload && (
                                            <Grid item>
                                                <Button
                                                    startIcon={<RefreshIcon />}
                                                    color="primary"
                                                    label="reload"
                                                    isLoading={isRefetching}
                                                    onClick={() => {
                                                        query.refetch();
                                                    }}
                                                />
                                            </Grid>
                                        )}

                                        {canAddItem && (
                                            <Grid item>
                                                <Button
                                                    startIcon={<AddIcon />}
                                                    color="primary"
                                                    label="addNew"
                                                    onClick={() => {
                                                        if (newFormPath) {
                                                            push(newFormPath);
                                                        } else if (formPath) {
                                                            push(formPath);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {facet && facetPosition === "top" && (
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    {facet}
                                </Grid>
                            </Grid>
                        )}
                    </>
                </Spacing>
            )}
            <Spacing spacing={1}>
                {facet && facetPosition === "left" ? (
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={2}
                        >
                            {facet}
                        </Grid>
                        <Grid
                            item
                            xs={10}
                        >
                            {table}
                        </Grid>
                    </Grid>
                ) : (
                    table
                )}
            </Spacing>

            <AcceptRejectDialog
                isOpen={itemToRemove !== undefined}
                onAccept={() => {
                    showInfoMessage("Usuwanie");
                    removeItem();
                }}
                onReject={() => {
                    setItemToRemove(undefined);
                }}
                dialogTitle={t(common.remove)}
            >
                <Typography variant="body1">
                    {t(common.areYouSure, {
                        what: `${t(common.toRemove)} '${
                            itemToRemove !== undefined
                                ? getItemRemoveName
                                    ? getItemRemoveName(itemToRemove)
                                    : itemToRemove?.name
                                : "please let know IT how to make it"
                        }'`,
                    })}
                </Typography>
            </AcceptRejectDialog>
        </>
    );
};

export default QueryTable;
