import React from "react";
import { Namespace } from "translations/Translation";
import { useTranslation } from "utils-ts/hooks";

const TranslateCell: React.FC<{ value: string | unknown | undefined; namespace?: Namespace }> = ({ value, namespace = "common" }): JSX.Element => {
    const { t } = useTranslation(namespace);

    if (!value) {
        return <></>;
    }

    return <>{t((value || "").toString())}</>;
};

export default TranslateCell;
