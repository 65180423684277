import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useTableStyles = makeStyles()((theme: Theme) => ({
    cell: {
        wordBreak: "normal",
    },
    dateCell: {
        maxWidth: "120px",
        minWidth: "80px",
    },
    booleanCell: {
        maxWidth: "100px",
        minWidth: "70px",
        textAlign: "center",
    },
    iconCell: {
        maxWidth: "20px",
        textAlign: "center",
    },
    numericCell: {
        textAlign: "right",
    },
    icon: {
        fontSize: "inherit",
        color: theme.palette.secondary.main,
    },
}));

export default useTableStyles;
