import { makeStyles } from "tss-react/mui";
import React, { useEffect } from "react";
import Draggable, { DraggableData } from "react-draggable";
import { AddIcCall } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { CustomerContactForm } from "views-ts/customerContact";

type Position = { x: number; y: number };

const localStoragePostionName = "customerContact_position";

const useStyles = makeStyles()((theme) => ({
    contactButton: {
        position: "fixed",
        bottom: 100,
        right: 10,
        overflowY: "auto",
    },
    button: {
        "backgroundColor": theme.palette.secondary.main,
        "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.secondary.light },
    },
}));

const customerContactPosition: Position = localStorage.getItem(localStoragePostionName)
    ? JSON.parse(localStorage.getItem(localStoragePostionName) ?? "")
    : { x: 0, y: 0 };

const CustomerContact: React.FC = () => {
    const height = 350;
    const width = 800;
    const bottom = 40;
    const right = 20;
    const heightOfTitleBar = 17;
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [lastPosition, setLastPosition] = React.useState(() => {
        const initialX =
            customerContactPosition.x - width - right < -window.innerWidth ? -(window.innerWidth - width - right) : customerContactPosition.x;
        const initialY =
            customerContactPosition.y - height - bottom + heightOfTitleBar < -window.innerHeight
                ? -(window.innerHeight - height - bottom)
                : customerContactPosition.y;
        return { x: initialX, y: initialY };
    });
    const { classes } = useStyles();
    const widgetContainerRef = React.useRef<HTMLInputElement>(null);
    const [widgetState, setWidgetState] = React.useState({
        visible: false,
        disabled: true,
        bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    });
    const onStart = (uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = widgetContainerRef?.current?.getBoundingClientRect();
        if (targetRect) {
            setWidgetState((prevState) => ({
                ...prevState,
                bounds: {
                    left: -targetRect?.left + uiData?.x,
                    right: clientWidth - (targetRect?.right - uiData?.x),
                    top: -targetRect?.top + uiData?.y + heightOfTitleBar,
                    bottom: clientHeight - (targetRect?.bottom - uiData?.y),
                },
            }));
        }
    };
    const onStop = (uiData: DraggableData) => {
        const newPosition: Position = { x: uiData.x, y: uiData.y };
        setLastPosition(newPosition);
        localStorage.setItem(localStoragePostionName, JSON.stringify(newPosition));
    };

    useEffect(() => {
        const handleResize = () => {
            setLastPosition((prevPosition) => {
                const maxX = -(window.innerWidth - width - right);
                const maxY = -(window.innerHeight - height - bottom);

                let newX = prevPosition.x;
                let newY = prevPosition.y;

                if (prevPosition.x < maxX) {
                    newX = maxX;
                }

                if (prevPosition.y < maxY) {
                    newY = maxY;
                }

                return { x: newX, y: newY };
            });
        };

        const mqString = `(resolution: ${window.devicePixelRatio}dppx)`;
        const media = matchMedia(mqString);

        if (isOpen) {
            handleResize();
            window.addEventListener("resize", handleResize);
            media.addEventListener("change", handleResize);
        }

        return () => {
            window.removeEventListener("resize", handleResize);
            media.removeEventListener("change", handleResize);
        };
    }, [isOpen]);
    const minimizeFC = React.useMemo(() => () => setIsOpen(false), []);
    return isOpen ? (
        <div
            style={{
                position: "fixed",
                bottom: bottom,
                right: right,
                zIndex: "1101",
                width: width,
                height: height,
            }}
        >
            <Draggable
                bounds={widgetState.bounds}
                onStart={(_, uiData) => onStart(uiData)}
                onStop={(_, uiData) => onStop(uiData)}
                cancel={"input"}
                enableUserSelectHack={false}
                position={lastPosition}
            >
                <div ref={widgetContainerRef}>
                    <CustomerContactForm
                        minimizeFunction={minimizeFC}
                        height={height}
                    />
                </div>
            </Draggable>
        </div>
    ) : (
        <div className={classes.contactButton}>
            <IconButton
                onClick={() => {
                    setIsOpen(true);
                }}
                className={classes.button}
            >
                <AddIcCall
                    fontSize="large"
                    sx={{ color: "white" }}
                />
            </IconButton>
        </div>
    );
};

export default CustomerContact;
