import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { TimePickerProps } from "control-types";
import { TimePicker } from "components-ts/controls";
import Controller from "./Controller";

type FormTimePickerProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
    FormControlProps<string, TFieldValues, TName>,
    "defaultValue" | "emptyValue"
> &
    Omit<TimePickerProps, "value" | "onChange" | "readOnly" | "label" | "error">;

const FormTimePicker = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    width,
    readOnly,
    includeSeconds,
    warning,
}: FormTimePickerProps<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field: { ref, onChange, disabled: _, value }, fieldState: { error } }) => {
                return (
                    <TimePicker
                        ref={ref}
                        value={value}
                        onChange={(newValue) => onChange(newValue)}
                        label={label}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        readOnly={readOnly}
                        includeSeconds={includeSeconds}
                        warning={warning}
                    />
                );
            }}
            defaultValue={""}
            transform={(val) => (val ? val : "")}
            width={width}
        />
    );
};

export default FormTimePicker;
