import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { TreeItem } from "control-types";
import { TreePicker } from "components-ts/controls";
import { default as Controller } from "./Controller";

type Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = FormControlProps<
    string[],
    TFieldValues,
    TName
> & {
    items: TreeItem[];
    disableEmpty?: boolean;
} & (
        | {
              onCustomChange?: (newValue: string | undefined) => void;
              transformValue: (value: string) => string[];
              transformValueOnChange: (newValue: string[] | undefined) => string | undefined;
          }
        | {
              onCustomChange?: (newValue: string[] | undefined) => void;
              transformValue?: never;
              transformValueOnChange?: never;
          }
    );

const FormTreePicker = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    items,
    disableEmpty,
    emptyValue,
    width,
    readOnly,
    onCustomChange,
    transformValue,
    transformValueOnChange,
    warning,
    required,
}: Props<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller<string[], TFieldValues, TName>
            name={name}
            render={({ field: { ref, value, onChange, disabled: _, required: formRequired, ...fieldProps }, fieldState: { error } }) => {
                return (
                    <TreePicker
                        {...fieldProps}
                        label={label}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        onChange={(value: string[] | undefined) => {
                            if (transformValueOnChange) {
                                const newValue = transformValueOnChange(value);
                                onChange(newValue);
                                if (onCustomChange) {
                                    onCustomChange(newValue);
                                }
                            } else {
                                onChange(value);
                                if (onCustomChange) {
                                    onCustomChange(value);
                                }
                            }
                        }}
                        items={items}
                        value={transformValue ? transformValue(value) : value}
                        disableEmpty={disableEmpty}
                        readOnly={readOnly}
                        required={formRequired || required}
                        warning={warning}
                    />
                );
            }}
            defaultValue={defaultValue}
            transform={(val) => (val ? val : emptyValue ?? [])}
            width={width}
        />
    );
};

export default FormTreePicker;
