import { useGetCacheQuery, useGetQuery } from "api/hooks";
import type { ProducerExternalUserAutocompleteModel } from "Operations";

const queryKey = "api/external-users/autocomplete/";

export const useFindProducers = () => {
    return useGetCacheQuery<Array<ProducerExternalUserAutocompleteModel>>({
        app: "operations",
        url: queryKey + "producers",
    });
};
