import { FieldPath, FieldValues } from "react-hook-form";
import moment from "moment";
import { FormControlProps } from "./types";
import { DateTimePicker } from "components-ts/controls";
import { default as Controller } from "./Controller";

type FormDateTimeProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = FormControlProps<moment.Moment, TFieldValues, TName> & {
    disableFuture?: boolean;
    disablePast?: boolean;
};

const FormDateTime = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    width,
    useGridItem,
    readOnly = false,
    disableFuture,
    disablePast,
    warning,
}: FormDateTimeProps<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller<moment.Moment | undefined, TFieldValues, TName>
            name={name}
            render={({ field: { ref, value, onChange, disabled: _, ...fieldProps }, fieldState: { error } }) => {
                return (
                    <DateTimePicker
                        ref={ref}
                        onChange={(newValue) => onChange(newValue)}
                        {...fieldProps}
                        value={value ? (moment.isMoment(value) ? value : moment(value)) : undefined}
                        label={label}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        disableFuture={disableFuture}
                        disablePast={disablePast}
                        readOnly={readOnly}
                        warning={warning}
                    />
                );
            }}
            defaultValue={defaultValue}
            useGridItem={useGridItem}
            width={width}
        />
    );
};

export default FormDateTime;
