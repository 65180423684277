import { generatePath } from "react-router-dom";
import { useCreateOrUpdateMutation, useGetQuery } from "api/hooks";
import { ShopAvailability, ShopConfiguration, ShopConfigurationDto, ShopConfigurationListResponse, ShopConfigurationPageRequest } from "Crawler";

const queryKey = "api/shop-configuration/";

const url = (id?: string) => generatePath(`${queryKey}:id?`, { id: id ? id : null });

export const useGetAllShopConfigurations = () => {
    return useGetQuery<ShopConfigurationDto[]>({
        app: "crawler",
        url: queryKey + "all",
    });
};

export const useGetAllShopAvailabilities = () => {
    return useGetQuery<ShopAvailability[]>({
        app: "crawler",
        url: queryKey + "availabilities",
    });
};

export const useFindShopConfigurations = (initailParams: ShopConfigurationPageRequest) => {
    return useGetQuery<ShopConfigurationListResponse, ShopConfigurationPageRequest>({
        app: "crawler",
        url: url(),
        queryParams: initailParams,
    });
};

export const useGetShopConfiguration = (id?: string) => {
    return useGetQuery<ShopConfiguration>({
        app: "crawler",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useUpdateShopConfiguration = (id?: string) => {
    return useCreateOrUpdateMutation<ShopConfiguration, ShopConfiguration>({
        app: "crawler",
        url: url(id),
        id: id,
    });
};
