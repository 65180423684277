import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const formInitialState = {};

const listInitialState = defaultListState;

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.getCorrectionRequest.request:
            return {
                ...state,
                isLoading: true,
            };
        case types.getCorrectionRequest.success:
            return {
                ...payload,
                isLoading: false,
            };
        case types.getCorrectionRequest.failure:
            return {
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.getCorrectionRequests.request:
            return {
                ...state,
                isLoading: true,
            };
        case types.getCorrectionRequests.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getCorrectionRequests.failure:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export const correctionRequest = combineReducers({
    form,
    list,
});
